import create from 'zustand';
import { getLang, Language, setLang } from './locale';

type DNBLocaleStorageTypes = {
  locale: Language;
  setLocale: (lang: Language, daysValid?: number) => void;
};

/**
 * A language hook that will update locale immediately after change,
 * even if that change was made in another component (e.g. in the Framework Footer).
 * Returns a set of two values: { locale, setLocale }.
 */
export const useLanguage = create<DNBLocaleStorageTypes>((set) => ({
  locale: getLang(),
  setLocale: (locale: Language) => {
    set({ locale });
    setLang(locale);
  },
}));
