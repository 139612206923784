import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHeaderStore } from '@dnb/header-controls';
import { useMenuStore } from '@dnb/menu';
import { format } from 'date-fns';
import translationKeys from '../../../lib/translationKeys';
import styles from './LoginHistory.module.scss';

const LoginHistory = () => {
  const { lastLogin } = useMenuStore();
  const heading = useHeaderStore((state) => state.profile.heading);

  return (
    <div className={styles.loginHistory}>
      <div>
        <FormattedMessage id={translationKeys.dnb_header_lastLogin} />:
      </div>
      <div>
        {lastLogin ? format(lastLogin, 'dd. MMM.')?.toLowerCase() : null}{' '}
        <FormattedMessage id={translationKeys.dnb_header_oclock} />{' '}
        {lastLogin ? format(lastLogin, 'k:m') : null}. {heading}
      </div>
    </div>
  );
};

export default LoginHistory;
