import React, { FunctionComponent } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from '@dnb/eufemia';
import translationKeys from '../../lib/translationKeys';
import styles from './NavigateToTop.module.scss';

function scrollToTop() {
  const supportsNativeSmoothScroll =
    'scrollBehavior' in document.documentElement.style;
  const prefersReducedMotion = window.matchMedia(
    '(prefers-reduced-motion: reduce)'
  ).matches;
  const scrollBehavior =
    supportsNativeSmoothScroll && !prefersReducedMotion ? 'smooth' : 'auto';

  window.scrollTo({ top: 0, left: 0, behavior: scrollBehavior });

  // Navigate focus to the first focusable element
  const focusableElements: NodeListOf<HTMLElement> = document.querySelectorAll(
    'button, a, input, select, textarea, [tabindex]:not([tabindex="-1"])'
  );
  focusableElements[0].focus({
    preventScroll: true,
  });
  focusableElements[0].blur();
}

const NavigateToTop: FunctionComponent = () => {
  const intl = useIntl();
  return (
    <div className={styles.navigateToTop}>
      <div className={styles.navigateToTopSection}>
        <Button
          on_click={scrollToTop}
          aria-label={intl.formatMessage({
            id: 'dnb_footer_nav_to_top_aria_label',
          })}
          icon="arrow_up"
          icon_position="left"
          variant="tertiary"
          className={styles.navigateToTopButton}
        >
          <FormattedMessage id={translationKeys.dnb_footer_nav_to_top} />
        </Button>
      </div>
    </div>
  );
};

export default NavigateToTop;
