import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { email_medium } from '@dnb/eufemia/icons';
import { MenuButton } from '@dnb/header';
import { navigate } from 'gatsby';
import translationKeys from '../../lib/translationKeys';
import styles from './InboxButton.module.scss';

// TODO: Replace this with real implementation when notifications service is ready
const getNotificationsNumber = () => 3;

const InboxButton = () => {
  const intl = useIntl();
  const notifications = getNotificationsNumber();
  const notificationsButtonAriaLabel = intl.formatMessage(
    { id: translationKeys.dnb_header_activity_notifications_alt_text },
    { numNotifications: notifications }
  );
  return (
    <MenuButton
      id={styles.dnb_header_inbox_button}
      text={
        <FormattedMessage id={translationKeys.dnb_header_activity_button} />
      }
      icon={email_medium}
      aria-label={notificationsButtonAriaLabel}
      badge={notifications}
      on_click={() => navigate('/')}
      disabled // TODO: Enable once notifications and authentication are implemented
    />
  );
};

export default InboxButton;
