import React from 'react';
import Anchor from '@dnb/eufemia/elements/Anchor';
import { LinkObject } from '../../Footer';
import styles from './LinksSection.module.scss';

type LinksSectionProps = {
  links: LinkObject[];
};

const LinksSection: React.FunctionComponent<LinksSectionProps> = ({
  links = [],
}) => (
  <ul className={styles.linksSection}>
    {links.map((link) => (
      <li key={link.url}>
        <Anchor
          className="dnb-anchor--contrast"
          href={link.url}
          aria-label={link.ariaLabel}
        >
          {link.text}
        </Anchor>
      </li>
    ))}
  </ul>
);

export type { LinksSectionProps };
export default LinksSection;
