import React from 'react';
import MenuContent from './components/MenuContent/MenuContent';
import MenuDrawer from './components/MenuDrawer/MenuDrawer';
import { useMenuStore } from './store/MenuStore';

export const Menu = () => {
  const { isLoggedIn, isMenuOpen, setMenuOpen } = useMenuStore();

  return (
    <MenuDrawer isOpen={isMenuOpen} onClose={() => setMenuOpen(false)}>
      <MenuContent isLoggedIn={isLoggedIn} />
    </MenuDrawer>
  );
};

Menu.displayName = 'Menu';
