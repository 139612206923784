export const retailContent = {
  no: {
    name: 'Privat',
    rootPath: '/privat',
    tabKey: 'privat',
    menuItems: [
      {
        title: 'Konto',
        url: '/accounts',
        icon: 'account_medium',
        description:
          'Få full oversikt over kontoene dine. Her kan du også åpne nye kontoer, og se alle betalinger og transaksjoner.',
      },
      {
        title: 'Lån',
        url: '/loans',
        icon: 'loan_medium',
        description:
          'Skal du kjøpe bolig, bil eller andre ting? Her finner du gode råd og løsninger når du trenger lån.',
      },
      {
        title: 'Sparing',
        url: '/savings',
        icon: 'savings_account_medium',
        description:
          'Se våre gode spareprodukter, og finn ut om du vil spare på konto eller investere i fond eller aksjer.',
      },
      {
        title: 'Pensjon',
        url: '/pension',
        icon: 'pension_medium',
        description:
          'Lær mer om pensjon og hvilke grep du kan gjøre selv for å påvirke din pensjon.',
      },
      {
        title: 'Kort',
        url: '/cards',
        icon: 'card_medium',
        description:
          'Kortene våre er tilpasset de fleste livsfaser. Se hvilke programmer og fordeler som er knyttet til de ulike kortene.',
      },
      {
        title: 'Betalinger',
        url: '/payments',
        icon: 'pay_from_medium',
        description:
          'Opprett faste oppdrag og betalingsavtaler som eFaktura og AvtaleGiro, og få oversikt over betalinger til forfall.',
      },
      {
        title: 'Forsikringer',
        url: '/insurance',
        icon: 'insurance_medium',
        description:
          'Forsikre deg selv, din familie og dine verdier, så slipper du å bekymre deg hvis noe uforutsett skulle skje.',
      },
      {
        title: 'Bolig',
        url: '/property',
        icon: 'house_1_medium',
        description:
          'Skal du selge eller kjøpe bolig? DNB Eiendom er Norges ledende eiendomsmekler. Se våre tips og råd.',
      },
      {
        title: 'Pengebruk',
        url: '/spendings',
        icon: 'coins_2_medium',
        description:
          'Få bedre oversikt og se hva pengene dine går til. Her kan du også kategorisere betalinger og opprette budsjetter.',
      },
    ],
  },
  en: {
    name: 'Privat',
    rootPath: '/privat',
    tabKey: 'privat',
    menuItems: [
      {
        title: 'Accounts',
        url: '/accounts',
        icon: 'account_medium',
        description:
          'Get a full overview of your accounts. Here you can also open new accounts and check your payments and transactions.',
      },
      {
        title: 'Loans',
        url: '/loans',
        icon: 'loan_medium',
        description:
          'Planning to buy a new car, a new home or something else? Here you’ll find good advice and solutions when you need a loan.',
      },
      {
        title: 'Savings',
        url: '/savings',
        icon: 'savings_account_medium',
        description:
          'From regular savings accounts to mutual funds and shares. Find a savings product that suits you.',
      },
      {
        title: 'Pensjon',
        url: '/pension',
        icon: 'pension_medium',
        description:
          'Learn more about pension and what steps you can take to influence your pension.',
      },
      {
        title: 'Cards',
        url: '/cards',
        icon: 'card_medium',
        description:
          'We offer a wide range of bank cards with customer programs and benefits to suit your needs in different phases of life.',
      },
      {
        title: 'Payments',
        url: '/payments',
        icon: 'pay_from_medium',
        description:
          'Set up recurring payments and payment agreements like eInvoice and AvtaleGiro and see all upcoming payments.',
      },
      {
        title: 'Insurance',
        url: '/insurance',
        icon: 'insurance',
        description:
          'With our insurance products, you can rest assured that you, your family and valuables are well protected.',
      },
      {
        title: 'Property',
        url: '/property',
        icon: 'house_1_medium',
        description:
          'Selling or buying a new home? DNB Eiendom is one of the oldest and largest realtors in Norway. See our tips and advice.',
      },
      {
        title: 'Spendings',
        url: '/spendings',
        icon: 'coins_2_medium',
        description:
          'Get a better overview and see where your money is going. Here you can also categorise payments and set up budgets.',
      },
    ],
  },
};
