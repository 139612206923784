import React from 'react';
import { FormattedMessage } from 'react-intl';
import translationKeys from '../../../lib/translationKeys';

export interface Props {
  firstName?: string;
}

const GreetingMessage = ({ firstName }: Props) => {
  const currentHour = new Date(Date.now()).getHours();
  let translationKey: string;

  if (currentHour < 6) {
    translationKey = translationKeys.dnb_header_greeting_night;
  } else if (currentHour < 9) {
    translationKey = translationKeys.dnb_header_greeting_morning;
  } else if (currentHour < 12) {
    translationKey = translationKeys.dnb_header_greeting_beforenoon;
  } else if (currentHour < 18) {
    translationKey = translationKeys.dnb_header_greeting_afternoon;
  } else {
    translationKey = translationKeys.dnb_header_greeting_evening;
  }

  return (
    <>
      <FormattedMessage id={translationKey} />
      {firstName && ','}
    </>
  );
};

export default GreetingMessage;
