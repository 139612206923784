exports.components = {
  "component---dynamic-routing-demo-src-pages-dynamic-routing-demo-index-tsx": () => import("./../../../../dynamic-routing-demo/src/pages/dynamic-routing-demo/index.tsx" /* webpackChunkName: "component---dynamic-routing-demo-src-pages-dynamic-routing-demo-index-tsx" */),
  "component---dynamic-routing-demo-src-pages-dynamic-routing-demo-my-param-tsx": () => import("./../../../../dynamic-routing-demo/src/pages/dynamic-routing-demo/[myParam].tsx" /* webpackChunkName: "component---dynamic-routing-demo-src-pages-dynamic-routing-demo-my-param-tsx" */),
  "component---error-boundary-demo-src-pages-error-boundary-demo-error-boundary-tsx": () => import("./../../../../error-boundary-demo/src/pages/error-boundary-demo/error-boundary.tsx" /* webpackChunkName: "component---error-boundary-demo-src-pages-error-boundary-demo-error-boundary-tsx" */),
  "component---error-boundary-demo-src-pages-error-boundary-demo-global-error-boundary-tsx": () => import("./../../../../error-boundary-demo/src/pages/error-boundary-demo/global-error-boundary.tsx" /* webpackChunkName: "component---error-boundary-demo-src-pages-error-boundary-demo-global-error-boundary-tsx" */),
  "component---error-boundary-demo-src-pages-error-boundary-demo-index-tsx": () => import("./../../../../error-boundary-demo/src/pages/error-boundary-demo/index.tsx" /* webpackChunkName: "component---error-boundary-demo-src-pages-error-boundary-demo-index-tsx" */),
  "component---error-boundary-demo-src-pages-error-boundary-demo-use-error-handler-tsx": () => import("./../../../../error-boundary-demo/src/pages/error-boundary-demo/use-error-handler.tsx" /* webpackChunkName: "component---error-boundary-demo-src-pages-error-boundary-demo-use-error-handler-tsx" */),
  "component---feedback-form-demo-src-pages-feedback-form-demo-index-tsx": () => import("./../../../../feedback-form-demo/src/pages/feedback-form-demo/index.tsx" /* webpackChunkName: "component---feedback-form-demo-src-pages-feedback-form-demo-index-tsx" */),
  "component---feedback-form-demo-src-pages-feedback-form-demo-page-2-tsx": () => import("./../../../../feedback-form-demo/src/pages/feedback-form-demo/page-2.tsx" /* webpackChunkName: "component---feedback-form-demo-src-pages-feedback-form-demo-page-2-tsx" */),
  "component---libs-dnb-components-error-pages-src-pages-404-tsx": () => import("./../../../../../../libs/dnb-components/error-pages/src/pages/404.tsx" /* webpackChunkName: "component---libs-dnb-components-error-pages-src-pages-404-tsx" */),
  "component---libs-dnb-components-error-pages-src-pages-500-tsx": () => import("./../../../../../../libs/dnb-components/error-pages/src/pages/500.tsx" /* webpackChunkName: "component---libs-dnb-components-error-pages-src-pages-500-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-translations-tsx": () => import("./../../../src/pages/translations.tsx" /* webpackChunkName: "component---src-pages-translations-tsx" */)
}

