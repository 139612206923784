import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getLocalAuthRedirectURL } from '@dnb/authentication';
import { log_in_medium } from '@dnb/eufemia/icons';
import { MenuButton } from '@dnb/header';
import { navigate } from 'gatsby';
import translationKeys from '../../lib/translationKeys';
import styles from './LoginButton.module.scss';

const LoginButton = () => {
  return (
    <MenuButton
      id={styles.dnb_login_button}
      text={<FormattedMessage id={translationKeys.dnb_header_login_button} />}
      icon={log_in_medium}
      on_click={() => navigate(getLocalAuthRedirectURL('/'))}
    />
  );
};

export default LoginButton;
