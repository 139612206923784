// NB: during Gatsby build we transform the path to start with a dot or slash
import translatedPaths from '@dnb/path-translation/cache-dummy/translated-paths.js';
import { pick } from '@reach/router/lib/utils';

// Create a "pick" compliant data list
const routes = Object.entries(translatedPaths).map(([translated, path]) => {
  return { path, translated };
});

export default function translatePath(path: string) {
  if (routes.length === 0 || path === '/') {
    return path;
  }

  // Find a valid translated path that would fit the given path
  const match = pick(routes, path);
  if (match) {
    return handlePathTranslation(
      path,
      match.route.path,
      match.route.translated
    );
  }

  return path;
}

function handlePathTranslation(
  path: string,
  original: string,
  translated: string
) {
  // Make an expression we can check again and get out the dynamic parts
  const match = path.match(new RegExp(original.replace(/:[^/]*/g, '([^/]*)')));

  // If we have at least one match, then we know, we have to do work
  if (match && match[1]) {
    // Use the second one and all other
    let count = 1;
    const controll = path.split('/').filter(Boolean);
    const translatedPath =
      '/' +
      translated
        .split('/')
        .filter(Boolean)
        .map((part, i) => {
          // And replace them in the same order
          if (part.startsWith(':')) {
            part = match[count];
            count++;
          } else {
            // create a control we can validated against
            controll[i] = '([^/]*)';
          }

          return part;
        })
        .join('/');

    if (new RegExp('/' + controll.join('/')).test(translatedPath)) {
      translated = translatedPath + '/';
    } else if (typeof console !== 'undefined') {
      console.warn(
        `Path translation failed for "${path}" with this controll "${controll}"`
      );
    }
  }

  // has no trailing slash
  if (path.slice(-1) !== '/') {
    translated = translated.replace(/^(.*)\/$/, '$1');
  }

  // has no beginning slash
  if (path[0] !== '/') {
    translated = translated.replace(/^\/(.*)$/, '$1');
  }

  return translated;
}
