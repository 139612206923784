import { isAuthCookieValid, setAuthCookie } from './cookie';
import { isLocalAuthDevelopment, getLocalAuthRedirectURL } from './env';

export interface AuthenticationState {
  isLoggedIn: boolean;
}

export function getAuthenticationState(): AuthenticationState {
  if (isLocalAuthDevelopment()) {
    if (getLocalAuthRedirectURL('') === '/') {
      setAuthCookie('');
    }
    return { isLoggedIn: isAuthCookieValid() };
  }

  return { isLoggedIn: true };
}
