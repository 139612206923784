/**
 * Code inside here would run in every application
 *
 */
import { resetLevels } from '@dnb/eufemia/components/Heading';

/**
 * What happens here?
 *
 * We talk about the "gatsby-focus-wrapper".
 *
 * Because a browser sets its "activeElement" focus back to the body during a vanilla HTML page transition,
 * we still wants this behavior for our SPA.
 *
 * Not that a screen reader really needs this,
 * because Gatsby is smart and uses either the page title or the first H1 and injects this into the "gatsby-announcer" with aria-live enabled.
 *
 * Gatsby inherits the "tabindex" feature from @reach/router.
 * The negative side effect happens that our "wrapper" element has a tabindex of -1 (in order to be able to focus)
 * we loose the browsers selection feature. Like that one where you can select any text, and the browser sets its activeElement to the NEXT nearby focusable element once we hit the tab key.
 * This is nice feature (not everyone is aware of).
 *
 * Anyway, we now use a delay to help us out. We basically remove the tabindex after 1 sec from within "onRouteUpdate".
 * And set it again on "onPreRouteUpdate", so Gatsby can set focus again.
 *
 * 1. To verify our solution, log "document.activeElement" from within "onRouteUpdate"
 *
 * More info: why we have to have the tabindex https://reach.tech/router/accessibility
 * More info: The div is necessary to manage focus https://github.com/reach/router/issues/63#issuecomment-395988602
 */

let focusDelay = null;
export const onRouteUpdate = () => {
  resetLevels(1);

  // 1. Remove focus after a delay
  clearTimeout(focusDelay);
  focusDelay = setTimeout(() => {
    try {
      const elem = document.getElementById('gatsby-focus-wrapper');
      if (elem) {
        elem.removeAttribute('tabindex');
      }
    } catch (e) {
      console.warn(e);
    }
  }, 1e3);
};

export const onPreRouteUpdate = () => {
  // 2. set back focus
  try {
    clearTimeout(focusDelay);
    const elem = document.getElementById('gatsby-focus-wrapper');
    if (elem) {
      elem.setAttribute('tabindex', '-1');
    }
  } catch (e) {
    console.warn(e);
  }
};
