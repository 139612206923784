import React from 'react';
import {GlobalErrorBoundary} from '@dnb/error-boundary';
import Footer from '@dnb/footer';
import Layout, {AppsWrapper, AppWithSidebarWrapper} from '@dnb/layout';
import type {GatsbyBrowser} from 'gatsby';
import PageWrapper from './components/PageWrapper';
import populateBreadcrumb from './helpers/populate-breadcrumb';
import Sidebarmenu, {Header} from '@dnb/sidebarmenu';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}): JSX.Element => {
  const breadcrumbPath = populateBreadcrumb(props.location);
  return (
    <Layout>
      <Header breadcrumbPath={breadcrumbPath}></Header>
      <AppWithSidebarWrapper>
        <Sidebarmenu/>
        <AppsWrapper>
          <GlobalErrorBoundary>
            <PageWrapper {...props}>{element}</PageWrapper>
          </GlobalErrorBoundary>
        </AppsWrapper>
      </AppWithSidebarWrapper>
      <Footer/>
    </Layout>
  );
};
