import React from 'react';
import { wrapAppElement } from '@dnb/app';
import Layout from '@dnb/layout';

/**
 * Use the "wrapAppElement" to provide providers to every page,
 * but only for pages within this application.
 *
 * "wrapAppElement" needs an "appName" defined in the plugin options within the root application.
 * More details: /docs/WrapAppElement.md
 *
 */
export const wrapPageElement = wrapAppElement(({ element }) => {
  if (!globalThis.isRootApp) {
    element = <Layout>{element}</Layout>;
  }

  return element;
});
