import { useState, useCallback, useRef, useEffect } from 'react';

export const useOpenDropdown = (openButtonId: string, shouldOpen?: boolean) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = useCallback(
    () => setDropdownOpen((isOpen) => !isOpen),
    [setDropdownOpen]
  );
  const closeDropdown = useCallback(
    () => setDropdownOpen(false),
    [setDropdownOpen]
  );

  const refToPreventClose = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!shouldOpen) {
      setDropdownOpen(false);
    }
  }, [shouldOpen, setDropdownOpen]);

  const onRefOpen = useCallback(
    (event: Event) => {
      if (!refToPreventClose.current.contains(event.target as Node)) {
        toggleDropdown();
      }
    },
    [toggleDropdown]
  );

  useEffect(() => {
    const dismiss = (event: KeyboardEvent) =>
      event.key === 'Escape' && closeDropdown();

    if (isDropdownOpen) {
      document.addEventListener('click', onRefOpen);
      document.addEventListener('keydown', dismiss);
      refToPreventClose.current.focus();
    } else {
      document.getElementById(openButtonId)?.focus();
    }

    return () => {
      document.removeEventListener('click', onRefOpen);
      document.removeEventListener('keydown', dismiss);
    };
  }, [isDropdownOpen, onRefOpen, openButtonId, closeDropdown]);

  return {
    isDropdownOpen: isDropdownOpen,
    toggleDropdown,
    closeDropdown,
    refToPreventClose,
  };
};
