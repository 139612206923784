// Autogenerated file
const translationKeys = {
  dnb_menu_close: 'dnb_menu_close',
  dnb_menu_button: 'dnb_menu_button',
  dnb_menu_failing_fetch: 'dnb_menu_failing_fetch',
  dnb_menu_bigger: 'dnb_menu_bigger',
  dnb_menu_smaller: 'dnb_menu_smaller',
};

Object.freeze(translationKeys);

export default translationKeys;
