import React from 'react';
import {
  ErrorBoundary,
  ErrorBoundaryPropsWithComponent,
} from 'react-error-boundary';
import { Language } from '@dnb/locale';
import GlobalErrorFallback from './GlobalErrorFallback';

interface GlobalErrorBoundaryProps
  extends Partial<ErrorBoundaryPropsWithComponent> {
  children?: React.ReactNode;
  locale?: Language;
  showRetryButton?: boolean;
}

export default function GlobalErrorBoundary({
  children,
  onReset = null,
  ...props
}: GlobalErrorBoundaryProps) {
  return (
    <ErrorBoundary
      FallbackComponent={(fallbackProps) => (
        <GlobalErrorFallback {...fallbackProps} {...props} />
      )}
      onReset={
        typeof onReset === 'function' ? onReset : () => window.location.reload()
      }
      {...props}
    >
      {children}
    </ErrorBoundary>
  );
}
