// Autogenerated file
const translationKeys = {
  dnb_header_search_button: 'dnb_header_search_button',
  dnb_header_login_button: 'dnb_header_login_button',
  dnb_header_activity_button: 'dnb_header_activity_button',
  dnb_header_activity_notifications_alt_text:
    'dnb_header_activity_notifications_alt_text',
  dnb_header_me_button: 'dnb_header_me_button',
  dnb_header_greeting_night: 'dnb_header_greeting_night',
  dnb_header_greeting_morning: 'dnb_header_greeting_morning',
  dnb_header_greeting_beforenoon: 'dnb_header_greeting_beforenoon',
  dnb_header_greeting_afternoon: 'dnb_header_greeting_afternoon',
  dnb_header_greeting_evening: 'dnb_header_greeting_evening',
  dnb_header_me_program: 'dnb_header_me_program',
  dnb_header_me_program_plus: 'dnb_header_me_program_plus',
  dnb_header_me_program_young: 'dnb_header_me_program_young',
  dnb_header_me_program_saga: 'dnb_header_me_program_saga',
  dnb_header_me_program_pb: 'dnb_header_me_program_pb',
  dnb_header_me_program_intro: 'dnb_header_me_program_intro',
  dnb_header_me_log_out: 'dnb_header_me_log_out',
  dnb_aria_header_me_dropdown: 'dnb_aria_header_me_dropdown',
  dnb_header_switch_bank: 'dnb_header_switch_bank',
  dnb_header_switch_text: 'dnb_header_switch_text',
  dnb_header_switch_search_text: 'dnb_header_switch_search_text',
  dnb_header_switch_search_placeholder_text:
    'dnb_header_switch_search_placeholder_text',
  dnb_header_oclock: 'dnb_header_oclock',
  dnb_header_lastLogin: 'dnb_header_lastLogin',
  dnb_header_my_profile: 'dnb_header_my_profile',
  dnb_header_profile_details: 'dnb_header_profile_details',
  dnb_header_profile_address: 'dnb_header_profile_address',
  dnb_header_share_profile: 'dnb_header_share_profile',
};

Object.freeze(translationKeys);

export default translationKeys;
