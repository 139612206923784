import React, { FunctionComponent } from 'react';
import { Section } from '@dnb/eufemia/components';
import { ExtendedContentObject } from '../../Footer';
import InfoSection from '../InfoSection';
import LinksSection from '../LinksSection';
import styles from './FooterExtension.module.scss';

type FooterExtensionProps = ExtendedContentObject & {
  children?: React.ReactNode;
};

const FooterExtension: FunctionComponent<FooterExtensionProps> = ({
  links,
  leftSection,
  rightSection,
  children,
}) => (
  <Section
    style_type="emerald-green"
    className={styles.footerExtension}
    data-testid="dnb-footer-extension"
  >
    <div className={styles.footerExtensionContainer}>
      {links && (
        <div className={styles.footerLinksContainer}>
          <LinksSection links={links} />
        </div>
      )}

      {!links && (leftSection || rightSection) && (
        <div
          className={styles.extendedSection}
          data-testid="dnb-footer-extension-large"
        >
          <div className={styles.leftSectionContainer}>
            {leftSection?.map((infoItem) => (
              <InfoSection {...infoItem} key={infoItem.title} />
            ))}
          </div>
          <div className={styles.rightSectionContainer}>
            {rightSection?.map((linkItem) => (
              <InfoSection {...linkItem} key={linkItem.title} />
            ))}
          </div>
        </div>
      )}

      {children}
    </div>
  </Section>
);

export type { FooterExtensionProps };
export default FooterExtension;
