import React from 'react';
import { Modal } from '@dnb/eufemia/components';
import './MenuDrawer.scss';

type MenuProps = {
  isOpen: boolean;
  children: React.ReactNode;
  onClose?: () => void;
};

export default function MenuDrawer({ isOpen, children, onClose }: MenuProps) {
  return (
    <Modal
      id="dnb-menu"
      root_id="dnb-menu--root"
      mode="drawer"
      open_state={isOpen}
      hide_close_button="true"
      container_placement="top"
      trigger_hidden
      overlay_class="dnb-menu-modal-overlay"
      data-testid="MenuDrawer"
      on_close={onClose}
    >
      {children}
    </Modal>
  );
}
