import React from 'react';
import { Anchor, P } from '@dnb/eufemia';
import Icon from '@dnb/eufemia/components/Icon';
import * as icons from '@dnb/eufemia/icons';
import classnames from 'classnames';
import { IconObject } from '../../Footer';
import styles from './InfoSection.module.scss';

const LinkIcon = ({ url, ariaLabel, icon, name }: IconObject) => {
  const SocialMediaIcon = icons[`${icon}_medium`];

  if (!SocialMediaIcon) {
    console.warn(
      `Web Framework - Footer: Unable to find icon "${icon}", please use supported Eufemia icons: https://eufemia.dnb.no/icons/`
    );

    return null;
  }

  return (
    <Anchor
      href={url}
      target="_blank"
      rel="noreferrer"
      aria-label={ariaLabel}
      className={classnames(styles.socialMediaLink, 'dnb-anchor--no-style')}
    >
      <Icon
        icon={SocialMediaIcon}
        className={styles.socialMediaIcon}
        size="medium"
      />
      <P className="dnb-sr-only">{name}</P>
    </Anchor>
  );
};

export default LinkIcon;
