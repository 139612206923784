export const corporateContent = {
  no: {
    name: 'Bedrift',
    rootPath: '/bedrift',
    tabKey: 'bedrift',
    menuItems: [
      {
        title: 'Oversikt',
        url: '/',
        icon: 'list_medium',
        description:
          'Commodo labore voluptate cillum qui adipisicing eiusmod id ullamco occaecat ea excepteur sit dolore officia.',
      },
      {
        title: 'Kontoer og likviditet',
        url: '/liquidity',
        icon: 'account_medium',
        description:
          'Commodo labore voluptate cillum qui adipisicing eiusmod id ullamco occaecat ea excepteur sit dolore officia.',
      },
      {
        title: 'Betalinger',
        url: '/due-payments',
        icon: 'pay_from_medium',
        description:
          'Quis ex enim deserunt esse anim do cupidatat culpa mollit minim.',
      },
      {
        title: 'Finansiering',
        url: '/credit-hub',
        icon: 'coins_2_medium',
        description:
          'Quis ex enim deserunt esse anim do cupidatat culpa mollit minim.',
      },
      {
        title: 'Pensjon og forsikring',
        url: '/',
        icon: 'insurance_medium',
        description:
          'Quis ex enim deserunt esse anim do cupidatat culpa mollit minim.',
      },
      {
        title: 'Sparing og investering',
        url: '/',
        icon: 'savings_account_medium',
        description: 'Laboris aliqua id velit commodo exercitation.',
      },
      {
        title: 'Regnskap',
        url: '/',
        icon: 'wallet_medium',
        description:
          'Magna exercitation ullamco dolore sunt laboris ad amet ullamco irure.',
      },
      {
        title: 'Innsikt',
        url: '/',
        icon: 'stopwatch_medium',
        description:
          'Amet nulla proident labore labore dolor eu laboris do incididunt nisi minim.',
      },
      {
        title: 'Administrasjon',
        url: '/',
        icon: 'cog_medium',
        description:
          'Amet nulla proident labore labore dolor eu laboris do incididunt nisi minim.',
      },
    ],
  },
  en: {
    name: 'Corporate',
    rootPath: '/bedrift',
    tabKey: 'corporate',
    menuItems: [
      {
        title: 'Overview',
        url: '/',
        icon: 'list_medium',
        description:
          'Commodo labore voluptate cillum qui adipisicing eiusmod id ullamco occaecat ea excepteur sit dolore officia.',
      },
      {
        title: 'Accounts & liquidity',
        url: '/liquidity',
        icon: 'account_medium',
        description:
          'Commodo labore voluptate cillum qui adipisicing eiusmod id ullamco occaecat ea excepteur sit dolore officia.',
      },
      {
        title: 'Payments',
        url: '/due-payments',
        icon: 'pay_from_medium',
        description:
          'Quis ex enim deserunt esse anim do cupidatat culpa mollit minim.',
      },
      {
        title: 'Financing',
        url: '/credit-hub',
        icon: 'coins_2_medium',
        description:
          'Quis ex enim deserunt esse anim do cupidatat culpa mollit minim.',
      },
      {
        title: 'Pension & insurance',
        url: '/',
        icon: 'insurance_medium',
        description:
          'Quis ex enim deserunt esse anim do cupidatat culpa mollit minim.',
      },
      {
        title: 'Savings & investments',
        url: '/',
        icon: 'savings_account_medium',
        description: 'Laboris aliqua id velit commodo exercitation.',
      },
      {
        title: 'Accounting',
        url: '/',
        icon: 'wallet_medium',
        description:
          'Magna exercitation ullamco dolore sunt laboris ad amet ullamco irure.',
      },
      {
        title: 'Insights & analysis',
        url: '/',
        icon: 'stopwatch_medium',
        description:
          'Amet nulla proident labore labore dolor eu laboris do incididunt nisi minim.',
      },
      {
        title: 'Administration',
        url: '/',
        icon: 'cog_medium',
        description:
          'Amet nulla proident labore labore dolor eu laboris do incididunt nisi minim.',
      },
    ],
  },
};
