import React from 'react';
import { GlobalErrorBoundary } from '@dnb/error-boundary';
import EufemiaProvider from '@dnb/eufemia/shared/Provider';
import Head from '@dnb/helmet';
import { useLanguage } from '@dnb/locale';

const LocaleWrapper = ({ children }) => {
  const { locale } = useLanguage();

  return (
    <EufemiaProvider locale={locale}>
      <Head>
        <html lang={locale} />
      </Head>
      {children}
    </EufemiaProvider>
  );
};

export const wrapRootElement = ({ element }) => {
  return <LocaleWrapper>{element}</LocaleWrapper>;
};

export const wrapPageElement = ({ element }) => {
  return <GlobalErrorBoundary>{element}</GlobalErrorBoundary>;
};
