import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider as EufemiaProvider } from '@dnb/eufemia/shared';
import { useLanguage } from '@dnb/locale';
import { useMediaQuery } from 'react-responsive';
import {H2, Skeleton} from '@dnb/eufemia';
import styles from './Menu.module.scss';
import { GetMenuItemListResponse } from './MenuServiceDataType';
import MenuContent from './components/MenuContent/MenuContent';
import { useMenuStore } from './store/MenuStore';
import translations from "./lib/translations";
import axios from "axios";
import { Modal, ProgressIndicator } from '@dnb/eufemia';

export const Menu = () => {
  const {
    menuItemList,
    setMenuItemList,
    setMenuOpen,
    setReturnToSmallView,
    loading,
    setLoading,
    responseError,
    setResponseError
  } = useMenuStore();

  const isDesktop = useMediaQuery({ minWidth: '50em' });
  const [prevIsDesktop, setPrevIsDesktop] = useState(isDesktop);
  const { locale } = useLanguage();
  const MENU_LOGIN_URL = process.env.GATSBY_MENU_LOGIN_URL;
  const MENU_URL = process.env.GATSBY_MENU_URL;
  const isDevelopment = window.location.hostname.indexOf('localhost') !== -1;

  const getMenuData = async () => {
    return await axios.get<GetMenuItemListResponse>(MENU_URL, { withCredentials: true })
  };

  const showPageContent = () => {
    const elem = document.getElementById('dnb-app-content');
    if (elem) {
      elem.style.visibility = 'visible';
    }
  };

  //hide page window before login
  const showLoginPageSpinner = () => (
      <Modal
          id="loginSpinner"
          openState={true}
          omitTriggerButton={true}
          preventClose={true}
          spacing={false}
          max_width="12rem"
          fullscreen={false}
          align_content="centered"
          hide_close_button
          prevent_close={false}
        >
            <ProgressIndicator
              type="circular"
              show_label
              label_direction="vertical"
              top="large"
              bottom="large"
              size="large"
            />
        </Modal>
  );

  useEffect(() => {
    //to prevent extra rendering
    if (isDesktop !== prevIsDesktop) {
      const elem = document
        .getElementsByClassName('menuNavigation')
        .item(0) as HTMLElement;
      if (elem) {
        elem.style.display = 'block';
      }
      setMenuOpen(true);
      setReturnToSmallView(false);
      setPrevIsDesktop(isDesktop);
    }
  }, [isDesktop]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      typeof menuItemList === 'undefined' ||
      menuItemList.length === 0 ||
      responseError
    ) {
      setLoading(true);
      getMenuData()
        .then((getMenuItemListResponse) => {
          if (getMenuItemListResponse.data != null) {
            setMenuItemList(getMenuItemListResponse.data?.menuItems);
            setLoading(false);
          }
          showPageContent();
        })
        .catch((e) => {
          if((e?.response?.status === 401 || typeof e?.response === 'undefined') && !isDevelopment){
            window.location.href = MENU_LOGIN_URL;
          } else {
            showPageContent();
            setResponseError(true);
            setLoading(false);
          }
        });
    } else {
      //default
      showPageContent();
    }
  }, [responseError]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
      loading && !responseError? (
          <div>
            {isDesktop && <Skeleton show={true} >
              <div className={styles.skeletonPaceholder}>
                <H2 top bottom>Just a few fake invisible entries</H2>
                <H2 top bottom>Menu item </H2>
                <H2 top bottom>Menu item </H2>
                <H2 top bottom>Placeholder text</H2>
                <H2 top bottom>Menu item </H2>
              </div>
          </Skeleton>}
          {showLoginPageSpinner()}
        </div>
      ) : (
        <IntlProvider locale={locale} messages={translations[locale]}>
          <EufemiaProvider locale={locale}>
            <MenuContent menuItemList={menuItemList} />
          </EufemiaProvider>
        </IntlProvider>
      )
  );

};

Menu.displayName = 'Sidebarmenu';
