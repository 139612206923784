module.exports = [{
      plugin: require('../../../../libs/dnb-components/layout/node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../libs/dnb-components/layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DNB","short_name":"DNB","start_url":"/","icon":"./public/apple-touch-icon.png","icons":[{"src":"./android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"./android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"display":"minimal-ui","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"68b678f24da500df7415b27c6c5d34a6"},
    },{
      plugin: require('../../../../libs/dnb-gatsby-themes/root-app/gatsby-browser.js'),
      options: {"plugins":[],"usedByApp":"root-app"},
    },{
      plugin: require('../../../../libs/dnb-gatsby-themes/app/gatsby-browser.js'),
      options: {"plugins":[],"usedByApp":"error-boundary-demo","__appDirectory":"/builds/dnb/dcf/dnb-web/apps/framework/error-boundary-demo"},
    },{
      plugin: require('../../error-boundary-demo/gatsby-browser.js'),
      options: {"plugins":[],"appName":"error-boundary-demo"},
    },{
      plugin: require('../../../../libs/dnb-gatsby-themes/app/gatsby-browser.js'),
      options: {"plugins":[],"usedByApp":"dynamic-routing-demo","__appDirectory":"/builds/dnb/dcf/dnb-web/apps/framework/dynamic-routing-demo"},
    },{
      plugin: require('../../dynamic-routing-demo/gatsby-browser.js'),
      options: {"plugins":[],"appName":"dynamic-routing-demo"},
    },{
      plugin: require('../../../../libs/dnb-gatsby-themes/app/gatsby-browser.js'),
      options: {"plugins":[],"usedByApp":"feedback-form-demo","__appDirectory":"/builds/dnb/dcf/dnb-web/apps/framework/feedback-form-demo"},
    },{
      plugin: require('../../feedback-form-demo/gatsby-browser.js'),
      options: {"plugins":[],"appName":"feedback-form-demo"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
