import React from 'react';
import styles from './AppWithSidebarWrapper.module.scss';

export default function AppWithSidebarWrapper({ children }) {

  return (
    <div data-testid="contentwithsidebarwrapper" className={styles.contentwithsidebarwrapper}>
      {children}
    </div>
  );
}
