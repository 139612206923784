import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Anchor from '@dnb/eufemia/elements/Anchor';
import { Language, useLanguage } from '@dnb/locale';
import translationKeys from '../../lib/translationKeys';

function getOtherLang(currentLang: string): Language {
  if (currentLang === Language.GB) return Language.NO;
  return Language.GB;
}
export type LanguageSelectorProps = {
  locale?: Language;
  setLocale?: ((lang: string) => void) | false;
};

const LanguageSelector: FunctionComponent<LanguageSelectorProps> = ({
  locale: localeProp,
  setLocale: setPropLocale,
}) => {
  const intl = useIntl();
  const { locale, setLocale } = useLanguage();
  const changeLanguage = () => {
    if (setPropLocale) {
      const changeToLang = getOtherLang(localeProp);
      setPropLocale(changeToLang);
    } else {
      const changeToLang = getOtherLang(locale);
      setLocale(changeToLang);
    }
  };

  return (
    <Anchor
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      onClick={changeLanguage}
      aria-label={intl.formatMessage({
        id: 'dnb_footer_go_to_language_aria_label',
      })}
      className="dnb-anchor--contrast"
      data-testid="dnb-footer-language-selector"
    >
      <FormattedMessage id={translationKeys.dnb_footer_go_to_language} />
    </Anchor>
  );
};

export default LanguageSelector;
