import React from 'react';
import { Icon } from '@dnb/eufemia/components';
import Button, {
  ButtonIcon,
  ButtonProps,
} from '@dnb/eufemia/components/Button';
import styles from './MenuButton.module.scss';

interface IconGroupProps {
  icon: ButtonIcon;
  badge?: number;
}

const IconGroup = ({ badge, icon }: IconGroupProps) => (
  <span className="dnb-icon dnb-icon--inherit-color dnb-icon--medium dnb-button__icon">
    <Icon className={styles.dnbButton__icon} icon={icon} />
    {badge && (
      <span className={styles.dnbButton__badge} data-testid="badge">
        {badge}
      </span>
    )}
  </span>
);

/**
 * TODO: Workaround, fix wanted: 'ref' prop from Eufemia ButtonProps threw type error.
 * This is likely a bug in Eufemia.
 */
interface MenuButtonProps extends Omit<ButtonProps, 'ref'> {
  badge?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arrowIcon?: any;
}

const getIcon = (icon: string) => {
  if (!icon) {
    return null;
  }

  return (
    <Icon
      icon={icon}
      aria-hidden
    />
  );
};

const MenuButton = ({ text, badge, icon, arrowIcon, ...props }: MenuButtonProps) => (
    <Button
      className={styles.dnbMenuButton}
      variant="tertiary"
      icon_position="top"
      icon={<IconGroup badge={badge} icon={icon}/>}
      {...props}
    >
      {text && <span className={styles.dnbButton__text}>{text}</span>}
      {arrowIcon && getIcon(arrowIcon)}
    </Button>
);

export default MenuButton;
