import React from 'react';
import styles from './Layout.module.scss';

interface LayoutProps {
  children?: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  return (
    <div key="layout" className={styles.wrapper}>
      {children}
    </div>
  );
}
Layout.displayName = 'Layout';

export default Layout;
