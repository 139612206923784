import React, {MutableRefObject, RefObject} from 'react';
import {Icon} from '@dnb/eufemia';
import * as icons from '@dnb/eufemia/icons/secondary_icons_medium.js';
import classNames from 'classnames';
import styles from './MenuItem.module.scss';
import {chevron_down as ChevronDownIcon} from "@dnb/eufemia/icons";
import {MenuItemWrapper} from "../MenuItemWrapper/MenuItemWrapper";

interface MenuItemProps {
  id?: string | number;
  title: string;
  icon?: string;
  text?: string;
  children?: React.ReactElement;
  url?: string;
  linkClassName?: string;
  hassubmenu?: boolean;
  targetRef?: RefObject<HTMLDivElement>;
  menuActive?: MutableRefObject<boolean>;
  closeMenu: (activeMenuItemRef: RefObject<HTMLDivElement>, menuActive: MutableRefObject<boolean>) => void;
  onClickHandler: (activeMenuItemRef: RefObject<HTMLDivElement>, url: string, menuActive: MutableRefObject<boolean>) => void;
}

const getIcon = (icon: string) => {
  if (!icon || !icons || !icons[icon]) {
    return null;
  }

  return (
    <Icon
      icon={icons[icon]}
      size="medium"
      className={`${styles.menuCardIcon} position-start`}
      data-testid={`dnb-menu-link-icon-${icon}`}
    />
  );
};

export default function MenuItem({
   icon,
   title,
   text,
   children,
   url,
   linkClassName,
   hassubmenu,
   targetRef,
   menuActive,
   closeMenu,
   onClickHandler,
 }: MenuItemProps) {

  const menuItemIcon = getIcon(icon);

  const cardTextWrapperCss = () => {
    if (menuItemIcon) {
      return classNames(styles.dnb_menuCardTextWrapper, 'dnb-menuCardTextWrapper', styles.menu_with_icon);
    } else {
      return classNames(styles.dnb_menuCardTextWrapper, 'dnb-menuCardTextWrapper');
    }
  };

  const replaceSpace = () => {
    return (hassubmenu)?title.replace(/;/g,"\u00A0"):title;
  }

  return (
    <div className={styles.dnb_menuCard}>
      <MenuItemWrapper
        url={url}
        classNameList={classNames(styles.dnb_menuLink, linkClassName)}
        hassubmenu={hassubmenu}
        targetRef={targetRef}
        menuActive={menuActive}
        closeMenu={closeMenu}
        onClickHandler={onClickHandler}
      >
        <div className={styles.dnb_menuCardContent}>
          {getIcon(icon)}
          <div className={cardTextWrapperCss()} title={text}>
            {replaceSpace()}
          </div>
          {hassubmenu &&
            <Icon className={classNames(styles.divider, 'dnb-chevronRightIcon')} icon={ChevronDownIcon} aria-hidden/>}
        </div>
      </MenuItemWrapper>

      {children}
    </div>
  );
}
