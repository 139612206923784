import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Logo } from '@dnb/eufemia/components';
import { Paragraph, Anchor } from '@dnb/eufemia/elements';
import classnames from 'classnames';
import { LinkObject, HomeLinkObject } from '../../Footer';
import LanguageSelector, { LanguageSelectorProps } from '../LanguageSelector';
import LinksSection from '../LinksSection';
import styles from './FooterBar.module.scss';

type FooterBarProps = {
  homeLink?: HomeLinkObject;
  links?: LinkObject[];
  children?: React.ReactNode;
};

const FooterBar: FunctionComponent<FooterBarProps & LanguageSelectorProps> = ({
  homeLink,
  links,
  children,
  locale,
  setLocale,
}) => {
  const intl = useIntl();
  const logoHref =
    homeLink?.url ||
    intl.formatMessage({ id: 'dnb_footer_default_home_page_url' });
  const logoAriaLabel =
    homeLink?.ariaLabel ||
    intl.formatMessage({
      id: 'dnb_footer_default_home_page_url_aria_label',
    });

  return (
    <div className={classnames('dnb-section', styles.footerBar)}>
      <div className={styles.footerBarContainer}>
        <Anchor
          className="dnb-anchor dnb-anchor--no-style"
          href={logoHref}
          aria-label={logoAriaLabel}
        >
          <Logo height="48" color="white" />
        </Anchor>

        <div className={styles.linksAndLanguageSection}>
          {links ? <LinksSection links={links} /> : <div>{children}</div>}
          <div className={styles.languageAndCopyrightSection}>
            {setLocale !== false ? (
              <LanguageSelector locale={locale} setLocale={setLocale} />
            ) : (
              <div />
            )}
            <Paragraph>&copy;</Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export type { FooterBarProps };
export default FooterBar;
