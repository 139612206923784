/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {MutableRefObject, RefObject, useRef} from 'react';
import Link from '@dnb/link';
import classNames from 'classnames';
import {useMenuStore} from "../../store/MenuStore";
import { navigate } from 'gatsby';

interface MenuItemWrapperProps {
  children?: React.ReactElement;
  url?: string;
  classNameList?: string;
  hassubmenu?: boolean;
  targetRef?: RefObject<HTMLDivElement>;
  menuActive?: MutableRefObject<boolean>;
  closeMenu: (activeMenuItemRef: RefObject<HTMLDivElement>, menuActive: MutableRefObject<boolean>) => void;
  onClickHandler: (activeMenuItemRef: RefObject<HTMLDivElement>, url: string, menuActive: MutableRefObject<boolean>) => void;
}

export const MenuItemWrapper = ({
                                          children,
                                          url,
                                          classNameList,
                                          hassubmenu,
                                          targetRef,
                                          menuActive,
                                          closeMenu,
                                          onClickHandler,
                                        }: MenuItemWrapperProps) => {

  const {
    isMenuOpen,
  } = useMenuStore();

  const linkRefTimer = useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const openWithAnimation = (_e: any) => {
    _e.preventDefault();
    closeMenu(targetRef, menuActive);
    if (linkRefTimer.current != null) {
      clearTimeout(linkRefTimer.current);
    }
    linkRefTimer.current = setTimeout(() => {
      navigate(url);
    }, 410);
    return false;
  };

  const LinkWrapper = () => (
    <Link
      to={url}
      className={classNameList}
      data-testid={`dnb-menu-link-${url}`}
      activeClassName="activeLink"
      onClick={(e) => openWithAnimation(e) }
    >
      {children}
    </Link>
  );

  const ButtonWrapper = () => {
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <div onClick={() => onClickHandler(targetRef, url, menuActive)}
           onKeyDown={function ({keyCode}: React.KeyboardEvent<HTMLElement>) {
             if (keyCode === 32 || keyCode === 13) {
               onClickHandler(targetRef, url, menuActive);
             }
           }}
           className={classNames(classNameList, menuActive.current && isMenuOpen ? "active" : "")}
           data-testid={`dnb-menu-link-${url}`}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
           tabIndex={0}
           aria-label="navigation-item"
      >
        {children}
      </div>
    );
  }

  return (hassubmenu ? <ButtonWrapper/> : <LinkWrapper/>);
}
