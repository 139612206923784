import create from 'zustand';

type Profile = {
  name?: string;
  program?: 'plus' | 'young' | 'saga' | 'pb' | 'intro';
  avatarSrc?: string;
  heading?: string;
  extraInfo?: 'Ipsum'
};

type DNBMenuStoreTypes = {
  profile: Profile;
  setProfile: (profile: Profile) => void;
};

export const useHeaderStore = create<DNBMenuStoreTypes>((set) => ({
  profile: {
    name: 'Guy Hawkins',
    program: null,
    avatarSrc: null,
    heading: 'Med BankID på mobil',
  },
  setProfile: (profile: Profile) => set({ profile }),
}));

export type { Profile, DNBMenuStoreTypes };
