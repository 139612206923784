import create from 'zustand';
import {getAuthenticationState} from '@dnb/authentication';
import {MutableRefObject, RefObject} from "react";
import {MenuItemData} from "../MenuServiceDataType";

type DNBMenuStoreTypes = {
  menuItemList: MenuItemData[] | [];
  setMenuItemList: (menuItemList: MenuItemData[]) => void;
  isMenuOpen: boolean;
  isMobileMenuOpen: boolean;
  setMenuOpen: (isMenuOpen: boolean) => void;
  setMobileMenuOpen: (isMobileMenuOpen: boolean) => void;
  isPrivateBankUser: boolean;
  setPrivateBankUser: (isPrivateBankUser: boolean) => void;
  toggleMenuOpen: () => void;
  toggleMobileMenuOpen: () => void;
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  returnToSmallView: boolean;
  setReturnToSmallView: (returnToSmallView: boolean) => void;
  toggleIsLoggedIn: () => void;
  menuLocale: locales;
  setMenuLocale: (menuLocale: locales) => void;
  //previous open/targeted menu item
  prevActiveMenuItemRef?: RefObject<HTMLDivElement>;
  setPrevActiveMenuItemRef: (prevActiveMenuItemRef: RefObject<HTMLDivElement>) => void;
  prevMenuActive?: MutableRefObject<boolean>;
  setPrevMenuActive: (prevMenuActive: MutableRefObject<boolean>) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  responseError: boolean;
  setResponseError: (responseError: boolean) => void;
};

export type locales = 'no' | 'en';
let menuStorageRefTimer = null;
const {isLoggedIn} = getAuthenticationState();

export const menuStore = create<DNBMenuStoreTypes>((set, get) => ({
  menuItemList: [],
  setMenuItemList: (menuItemList: MenuItemData[]) => {
    set({menuItemList});
  },
  returnToSmallView: false,
  setReturnToSmallView: (returnToSmallView: boolean) => {
    set({returnToSmallView});
  },
  isMenuOpen: true,
  setMenuOpen: (isMenuOpen: boolean) => {
    set({isMenuOpen});
  },
  isMobileMenuOpen: false,
  setMobileMenuOpen: (isMobileMenuOpen: boolean) => {
    /*horizontal animation - display none is used to control if items can be focused or not navigating with keyboard */
    const elem = document.getElementsByClassName("menuNavigation").item(0) as HTMLElement;
    clearTimeout(menuStorageRefTimer);
    if (elem != null && isMobileMenuOpen) {
      //display menu before animation
      elem.style.display = 'block';
      menuStorageRefTimer = setTimeout(() => {
        set({isMobileMenuOpen});
      }, 50);
    } else {
      set({isMobileMenuOpen});
        //hide after animation
      menuStorageRefTimer = setTimeout(() => {
          //if mobile menu is closed hide menu completely
          if(!isMobileMenuOpen){
            const elem = document.getElementsByClassName("menuNavigation").item(0) as HTMLElement;
            if(elem != null) elem.style.display = 'none';
          }
        }, 500);
    }
  },
  isPrivateBankUser: true,
  setPrivateBankUser: (isPrivateBankUser: boolean) => {
    set({isPrivateBankUser});
  },
  toggleMenuOpen: () => {
    const isMenuOpen = !get().isMenuOpen;
    get().setMenuOpen(isMenuOpen);
  },
  toggleMobileMenuOpen: () => {
    const isMobileMenuOpen = !get().isMobileMenuOpen;
    get().setMobileMenuOpen(isMobileMenuOpen);
  },
  isLoggedIn: isLoggedIn,
  setIsLoggedIn: (isLoggedIn: boolean) => {
    set({isLoggedIn});
  },
  toggleIsLoggedIn: () => {
    set({isLoggedIn: !get().isLoggedIn});
  },
  menuLocale: 'no',
  setMenuLocale: (menuLocale: locales) => {
    set({menuLocale});
  },
  prevActiveMenuItemRef: null,
  setPrevActiveMenuItemRef: (prevActiveMenuItemRef: RefObject<HTMLDivElement>) => {
    set({prevActiveMenuItemRef});
  },
  prevMenuActive: null,
  setPrevMenuActive: (prevMenuActive: MutableRefObject<boolean>) => {
    set({prevMenuActive});
  },
  menuBtnEnabled: true,
  loading: false,
  setLoading: (loading: boolean) => {
    set({loading});
  },
  responseError: false,
  setResponseError: (responseError: boolean) => {
    set({responseError});
  },
}));

/**
 * This this React hook inside your React components
 */
const useMenuStore = menuStore;

export {useMenuStore};
