type Breadcrumb = {
  text?: string;
  href?: string;
};

/**
 * The populateBreadcrumb function takes in the current location as a object and creates a breadcrumb for each part of the path. It will always add "Home" as the first breadcrumb item.
 * @param currentLocation The location object belonging to the current page (window.location or location from Gatsby)
 * @returns A list of breadcrumbs, where each breadcrumb is assigned a text (including capitalized first letter) and a href for the corresponding link
 */
export default function populateBreadcrumb(
  currentLocation: Location
): Breadcrumb[] {
  const home = [{ href: '/', text: '' }];
  const path = home.concat(
    currentLocation.pathname
      .split('/')
      .filter((pathname) => pathname !== '')
      .map((pathname) => ({
        text: convertPathName(pathname),
        href: '/' + pathname,
      }))
  );
  return path;
}

const convertPathName = (pathname: string): string => {
  return decodeURIComponent(capitalizeWords(pathname.split('-')).join(' '));
};

const capitalizeWords = (arr: string[]): string[] => {
  return arr.map((element) => {
    return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
  });
};
