import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider as EufemiaProvider } from '@dnb/eufemia/shared';
import { useLanguage } from '@dnb/locale';
import translations from '../../lib/translations';
import { SidebarMenuButton } from './SidebarMenuButton';

export function SidebarMenuButtonWrapper() {
  const { locale } = useLanguage();

  return (
    <IntlProvider locale={locale} messages={translations[locale]}>
      <EufemiaProvider locale={locale}>
        <SidebarMenuButton />
      </EufemiaProvider>
    </IntlProvider>
  );
}

SidebarMenuButtonWrapper.displayName = 'SidebarMenuButton';
