import React from 'react';
import Anchor from '@dnb/eufemia/elements/Anchor';
import classnames from 'classnames';
import { InfoObject } from '../../Footer';
import styles from './InfoSection.module.scss';
import LinkIcon from './LinkIcon';

const InfoSection: React.FunctionComponent<InfoObject> = ({
  title,
  links = [],
  texts = [],
  icons = [],
}) => (
  <dl className={styles.infoSection}>
    <dt className={classnames('dnb-p', 'dnb-p--medium', styles.dlTitle)}>
      {title}
    </dt>

    {links.map((link) => (
      <dd className={styles.dlDesc} key={link.url}>
        <Anchor className="dnb-anchor--contrast" href={link.url}>
          {link.text}
        </Anchor>
      </dd>
    ))}

    {texts.map((text) => (
      <dd className={classnames('dnb-p', styles.dlDesc)} key={text}>
        {text}
      </dd>
    ))}

    {icons?.length > 0 && (
      <div className={styles.socialMediaContainer}>
        {icons.map((icon) => (
          <dd className={styles.dlDesc} key={icon.url}>
            <LinkIcon {...icon} />
          </dd>
        ))}
      </div>
    )}
  </dl>
);

export default InfoSection;
