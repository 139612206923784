import { bottomBarContents } from '@framework/mock-data';
import { corporateContent } from '@framework/mock-data';
import { retailContent } from '@framework/mock-data';
import {locales} from './store/MenuStore';

export type menuLocale = {
  [key in locales]: MenuTabsContent[];
};
export interface MenuTabsContent {
  name: string;
  rootPath: string;
  tabKey: string;
  menuItems: MenuTabItems[];
}

export interface MenuTabItems {
  id?: number | string;
  title: string;
  url?: string;
  icon?: string;
  description?: string;
}

export interface MenuBottomBarContent {
  title: string;
  url: string;
}

export const getBottomBarContent = (locale: locales = 'no'): MenuBottomBarContent[] => {
  return bottomBarContents[locale];
}

export const getMenuContent = (locale: locales = 'no') : MenuTabsContent[]=> {
  return [retailContent[locale], corporateContent[locale]];
}
