import React from 'react';
import {Logo} from "@dnb/eufemia/components";
import {Link} from "@dnb/link";
import styles from './LogoButton.module.scss';
import classNames from "classnames";
import {useMediaQuery} from "react-responsive";
import {useMenuStore} from "../../../store/MenuStore";

export function LogoButton({viewport}) {
  const isDesktop = useMediaQuery({minWidth: '50em'});
  const {isMenuOpen, returnToSmallView} = useMenuStore();

  const desktopMenuCss = () => {
    if(isMenuOpen && !returnToSmallView) {
      return styles.dnbHeaderLogo + "";
    } else {
      return styles.dnbHeaderLogo + " " +styles.small;
    }
  }

  const logoButtonCss = () => {
    if (viewport === "tablet")
      return styles.dnbTabletHeaderLogoWrapper + "";
    else {
      if (isDesktop)
        return desktopMenuCss();
      else
        return styles.dnbMobileHeaderLogoWrapper + "";
    }
  };

  const logoButtonHeight = () => {
    if(isMenuOpen && !returnToSmallView) {
      return 48;
    } else {
       return 40;
    }
  };

  return (
    <Link to="/" className={classNames(logoButtonCss())} aria-label="DNB logo">
      <Logo height={logoButtonHeight()}/>
    </Link>
  );
}
