import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider as EufemiaProvider } from '@dnb/eufemia/shared';
import { Language, useLanguage } from '@dnb/locale';
import FooterBar, { FooterBarProps } from './components/FooterBar';
import FooterExtension, {
  FooterExtensionProps,
} from './components/FooterExtension';
import NavigateToTop from './components/NavigateToTop';
import translations from './lib/translations';

type LinkObject = {
  text: string;
  url: string;
  ariaLabel?: string;
};

type IconObject = {
  icon: string;
  url: string;
  ariaLabel?: string;
  name: string;
};

type InfoObject = {
  title: string;
  texts?: Array<string>;
  links?: Array<LinkObject>;
  icons?: Array<IconObject>;
};

type HomeLinkObject = {
  url: string;
  ariaLabel: string;
};

type ExtendedContentObject = {
  links?: LinkObject[];
  leftSection?: InfoObject[];
  rightSection?: InfoObject[];
};

type FooterObject = {
  homeLink?: HomeLinkObject;
  barContent?: { links: LinkObject[] };
  extendedContent?: ExtendedContentObject;
};

type CmsContentStructure = {
  [key: string]: FooterObject;
};

export type FooterProps = {
  /**
   * Content gathered from a CMS to populate the footer container.
   * Check out the full content object in the storybook docs.
   */
  cmsContent?: CmsContentStructure;

  /**
   * Pass in Footer parts as children if you want to override the
   * component setup.
   * Available parts: `Footer.Bar`, `Footer.NavigateToTop`, and `Footer.Extension`.
   */
  children?: React.ReactNode;

  /**
   * Override locale. Default set to use locale from @dnb/locale.
   * Supported languages in footer: English 'en-GB' and Norwegian 'nb-NO'
   */
  locale?: Language;

  /**
   * Override language selector. Default set to use setLocale from @dnb/locale.
   * Set this to false to disable language selection.
   */
  setLocale?: ((lang: string) => void) | false;
};

type FooterComponentTypes = {
  Bar: React.FunctionComponent<FooterBarProps>;
  Extension: React.FunctionComponent<FooterExtensionProps>;
  NavigateToTop: React.FunctionComponent;
};

export const Footer: React.FunctionComponent<FooterProps> &
  FooterComponentTypes = ({
  cmsContent = null,
  children,
  locale: localeFromProps,
  setLocale,
}) => {
  const { locale } = useLanguage();
  const footerLocale = localeFromProps || locale;
  const { barContent, extendedContent, homeLink }: FooterObject =
    (cmsContent && cmsContent[footerLocale]) || {};

  return (
    <IntlProvider
      locale={footerLocale}
      key={footerLocale}
      messages={translations[footerLocale]}
    >
      <EufemiaProvider locale={footerLocale}>
        <footer>
          {!children && (
            <>
              <NavigateToTop />

              {extendedContent && (
                <FooterExtension
                  links={extendedContent.links}
                  leftSection={extendedContent.leftSection}
                  rightSection={extendedContent.rightSection}
                />
              )}

              <FooterBar
                homeLink={homeLink}
                links={barContent?.links}
                locale={localeFromProps}
                setLocale={setLocale}
              />
            </>
          )}

          {children}
        </footer>
      </EufemiaProvider>
    </IntlProvider>
  );
};

export {
  LinkObject,
  IconObject,
  InfoObject,
  FooterObject,
  HomeLinkObject,
  CmsContentStructure,
  ExtendedContentObject,
};

Footer.Bar = FooterBar;
Footer.Extension = FooterExtension;
Footer.NavigateToTop = NavigateToTop;
Footer.displayName = 'Footer';

export default Footer;
