const AUTH_COOKIE_NAME = 'idtc';

export const isAuthCookieValid = () => {
  const [key, _] = getCookie(AUTH_COOKIE_NAME)?.split('=') ?? [];
  return !!key;
};

export const getCookie = (cookieName: string) => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieParts = decodedCookie.split('; ');
  return cookieParts.find((part) => part.startsWith(cookieName + '='));
};

export const deleteAuthCookie = () => {
  deleteCookie(AUTH_COOKIE_NAME);
};

export const deleteCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=.tech-03.net`;
};

export const setAuthCookie = (value: string) => {
  setCookie(AUTH_COOKIE_NAME, value);
};

export const setCookie = (name: string, value: string) => {
  document.cookie = `${name}=${value};`;
};
