import React from 'react';
import { FormattedMessage } from 'react-intl';
import { hamburger_medium, close_medium } from '@dnb/eufemia/icons';
import { MenuButton } from '@dnb/header';
import { useMenuStore } from '@dnb/sidebarmenu';
import translationKeys from '../../lib/translationKeys';
import styles from './SidebarMenuButton.module.scss';

export function SidebarMenuButton() {
  const { toggleMobileMenuOpen, isMobileMenuOpen } = useMenuStore();

  return (
    <div
      className={styles.sidebarMenuButtonWrapper}
      title="open-and-close-menu-button"
    >
      <MenuButton
        text={
          isMobileMenuOpen ? (
            <FormattedMessage id={translationKeys.dnb_menu_close} />
          ) : (
            <FormattedMessage id={translationKeys.dnb_menu_button} />
          )
        }
        icon={isMobileMenuOpen ? close_medium : hamburger_medium}
        on_click={() => {
          toggleMobileMenuOpen();
        }}
      />
    </div>
  );
}
