import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, Button, P } from '@dnb/eufemia';
import { camera } from '@dnb/eufemia/icons';
import classNames from 'classnames';
import translationKeys from '../../../lib/translationKeys';
import GreetingMessage from '../GreetingMessage/GreetingMessage';
import styles from './About.module.scss';

export type AboutProps = {
  name?: string;
  programType?: string;
  extraInfo?: string;
  avatarSrc?: string;
};

const PROGRAMS = ['plus', 'young', 'saga', 'pb', 'intro'];

const About = ({ name, programType, extraInfo, avatarSrc }: AboutProps) => {
  const needProgramType = false;
  const isProgramTypeValid = (program) => {
    if (!PROGRAMS.includes(program)) {
      console.warn(
        `Web Framework - Header: The program provided for the user profile is not found. Only the following program codes are supported: ${PROGRAMS}`
      );
      return false;
    }
    return true;
  };

  return (
    <>
      <div className={styles.aboutDescription}>
        {name ? (
          <div>
            <div className={classNames(styles.name)}>{name}</div>
            <div className={styles.extraInfo}>
              <span>Loren: </span>
              <span>{extraInfo}</span>
            </div>
          </div>
        ) : (
          <P className={styles.name}>
            <GreetingMessage />
          </P>
        )}

        {needProgramType && programType && isProgramTypeValid(programType) && (
          <P className={styles.program}>
            <FormattedMessage id={translationKeys.dnb_header_me_program} />{' '}
            <span className={styles.programType}>
              <FormattedMessage
                id={translationKeys[`dnb_header_me_program_${programType}`]}
              />
            </span>
          </P>
        )}
      </div>

      <div className={styles.avatar}>
        {!avatarSrc && name && (
          <Avatar size="large" hasLabel>
            {name}
          </Avatar>
        )}
        {avatarSrc && (
          <Avatar src={avatarSrc} alt="Profile picture" size="large" hasLabel />
        )}
        <Button
          className={styles.pictureButton}
          icon={camera}
          size="medium"
          aria-label={'change avatar photo'}
          disabled // TODO: Enable once upload picture is implemented
        />
      </div>
    </>
  );
};

export default About;
