import React from 'react';
import { Div, H1, Skeleton } from '@dnb/eufemia';
import { isEmpty } from 'lodash';
import styles from './SimpleHeader.module.scss';
import classnames from "classnames";

export interface Props {
  title: React.ReactNode;
  titleSize?: 'x-large' | 'xx-large';
  titleOption?: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  tabs?: React.ReactNode;
  isSkeleton?: boolean;
}

const SimpleHeader = ({
  title,
  titleSize = 'xx-large',
  titleOption,
  subtitle,
  children,
  tabs,
  isSkeleton = false,
}: Props) => {
  return (
    <Skeleton
      className={!tabs ? styles.bottomPadding : null}
      show={isSkeleton}
      data-testid={`simple-header${isSkeleton ? '-skeleton' : ''}`}
    >
      {getTitleWithTitleOption()}
      {getSubtitle()}
      {getChildren()}
      {getTabs()}
    </Skeleton>
  );

  function getTitle() {
    return isEmpty(title) ? null : (
      <H1
        data-testid="header-title"
        bottom="0"
        size={titleSize}
        className={classnames(styles.title, "simpleHeaderTitle")}
      >
        {title}
      </H1>
    );
  }

  function getTitleOption() {
    return isEmpty(titleOption) ? null : (
      <Div
        className={styles.titleOption}
        data-testid="header-title-option"
        top="large"
      >
        {titleOption}
      </Div>
    );
  }

  function getTitleWithTitleOption() {
    return (
      <Div className={styles.titleWithTitleOption}>
        {getTitle()}
        {getTitleOption()}
      </Div>
    );
  }

  function getSubtitle() {
    return isEmpty(subtitle) ? null : (
      <Div data-testid="header-subtitle" top="small">
        {subtitle}
      </Div>
    );
  }

  function getChildren() {
    return isEmpty(children) ? null : (
      <Div data-testid="header-children" top="large">
        {children}
      </Div>
    );
  }

  function getTabs() {
    return isEmpty(tabs) ? null : (
      <Div data-testid="header-tabs" className={styles.tabs} top="large">
        {tabs}
      </Div>
    );
  }
};

export default SimpleHeader;
