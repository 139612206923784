import React from 'react';
import { Section } from '@dnb/eufemia';
import MenuItem from '../MenuItem/MenuItem';
import styles from './MenuBottomBar.module.scss';

interface MenuBottomBarProps {
  links: BottomBarLink[];
}

interface BottomBarLink {
  url: string;
  title: string;
}

export default function MenuBottomBar({ links }: MenuBottomBarProps) {
  return (
    <Section
      style_type="lavender"
      className={styles.dnb_menuBottomBar}
      data-testid="dnb-menu-bottom-bar"
    >
      {links &&
        links.map(({ url, title }, index) => (
          <MenuItem
            key={title + index}
            data-testid={title}
            url={url}
            title={title}
            linkClassName={styles.link}
          />
        ))}
    </Section>
  );
}
