import React from 'react';
import { IntlProvider } from 'react-intl';
import { useLanguage } from '@dnb/locale';
import translations from '../../lib/translations';
import InboxButton from '../InboxButton/InboxButton';
import LoginButton from '../LoginButton/LoginButton';
import ProfileDropdown from '../Profiledropdown/ProfileDropdown';
import SearchButton from '../SearchButton/SearchButton';
import styles from './HeaderControls.module.scss';

export type HeaderItemsProps = { isLoggedIn?: boolean; shouldOpen?: boolean };

const AuthenticatedHeaderItems = ({ shouldOpen }: HeaderItemsProps) => {
  return (
    <>
      <SearchButton />
      <InboxButton />
      <ProfileDropdown shouldOpen={shouldOpen} />
    </>
  );
};

const DefaultHeaderItems = () => {
  return (
    <>
      <SearchButton />
      <LoginButton />
    </>
  );
};

const HeaderControlsNoWrapper = ({
  isLoggedIn,
  shouldOpen,
}: HeaderItemsProps) => {
  const { locale } = useLanguage();
  return (
    <IntlProvider locale={locale} messages={translations[locale]}>
      <div className={styles.dnbHeaderControls}>
        {isLoggedIn ? (
          <AuthenticatedHeaderItems shouldOpen={shouldOpen} />
        ) : (
          <DefaultHeaderItems />
        )}
      </div>
    </IntlProvider>
  );
};

export default HeaderControlsNoWrapper;
