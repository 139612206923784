import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { Breadcrumb } from '@dnb/eufemia';
import { useHeaderStore } from '@dnb/header';
import { useLanguage } from '@dnb/locale';
import { PageRendererProps } from 'gatsby';
import populateBreadcrumb from '../helpers/populate-breadcrumb';
import translations from '../lib/translations';
import styles from "./PageWrapper.module.scss";

interface PageProps {
  children: React.ReactElement;
}

const PageWrapper = ({
  location,
  children,
}: PageProps & PageRendererProps): JSX.Element => {
  const breadcrumbPath = populateBreadcrumb(location);
  const showBreadcrumb = breadcrumbPath.length > 1;
  const { locale } = useLanguage();
  const { setProfile } = useHeaderStore();

  useEffect(() => {
    setProfile({ name: 'Christopher Hawkins', program: 'saga', extraInfo: 'Ipsum', heading: 'Med BankID på mobil', });
  }, [setProfile]);

  return (
    <IntlProvider messages={translations[locale]} locale={locale}>
      {showBreadcrumb && <Breadcrumb data={breadcrumbPath} className={styles.breadcrumb} spacing />}
      {children}
    </IntlProvider>
  );
};

export default PageWrapper;
