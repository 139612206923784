import React from 'react';
import { wrapAppElement } from '@dnb/app';
import { GlobalErrorBoundary } from '@dnb/error-boundary';
import Layout from '@dnb/layout';

/**
 * Use the "wrapAppElement" from @dnb/app to provide providers to every page,
 * but only for pages within this application.
 */
export const wrapPageElement = wrapAppElement(({ element }) => {
  /**
   * !globalThis.isRootApp is only true when the app is run without the root app.
   * For example when running in development or in e-platform
   */
  if (!globalThis.isRootApp) {
    element = (
      <Layout>
        <GlobalErrorBoundary>{element}</GlobalErrorBoundary>
      </Layout>
    );
  }

  return element;
});
