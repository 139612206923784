import React from 'react';
import { FormattedMessage } from 'react-intl';
import { loupe_medium } from '@dnb/eufemia/icons';
import { MenuButton } from '@dnb/header';
import { navigate } from 'gatsby';
import translationKeys from '../../lib/translationKeys';
import styles from './SearchButton.module.scss';

const SearchButton = () => {
  return (
    <MenuButton
      id={styles.dnb_header_search_button}
      text={<FormattedMessage id={translationKeys.dnb_header_search_button} />}
      icon={loupe_medium}
      on_click={() => navigate('/')}
      disabled // TODO: Enable once search is implemented
    />
  );
};

export default SearchButton;
