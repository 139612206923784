import React from 'react';
import { MenuTabItems } from '../../MenuMaker';
import MenuItem from '../MenuItem/MenuItem';
import styles from './MenuTab.module.scss';

interface MenuBlockProps {
  content: MenuTabItems[];
}

function MenuTab({ content }: MenuBlockProps) {
  return (
    <div className={styles.dnb_menu_content_wrapper}>
      {content &&
        content.map(({ id, title, icon, description, url }, index) => (
          <MenuItem
            key={title + index}
            id={id}
            title={title}
            icon={icon}
            text={description}
            url={url}
          />
        ))}
    </div>
  );
}

export default MenuTab;
