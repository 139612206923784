import React, { FC, useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { Button, IconPrimary, Avatar, Input } from '@dnb/eufemia';
import { Modal } from '@dnb/eufemia/components';
import { useMenuStore } from '@dnb/menu';
import { onlineBankList } from '@framework/mock-data';
import { navigate } from 'gatsby';
import translationKeys from '../../../lib/translationKeys';
import styles from './SwitchBankModal.module.scss';

interface Props {
  onModalClose?: () => void;
}
type OnlineBankData = {
  name: string;
  url: string;
  isPrivate: boolean;
  id?: string;
  role?: string;
};

const SwitchBankModal: FC<Props> = ({ onModalClose }) => {
  const isDesktop = useMediaQuery({ minWidth: '50em' });
  const inputRef = useRef<HTMLInputElement>(null);
  const { menuLocale } = useMenuStore();
  const onOpen = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const intl = useIntl();
  const searchPlaceholder = intl.formatMessage({
    id: translationKeys.dnb_header_switch_search_placeholder_text,
  });
  const getFirstLetter = (name) => name.charAt(0).toLocaleUpperCase();

  const getOnlineBankList = (items: Array<OnlineBankData>) => {
    return items.length ? (
      <div className={styles.onlineBank}>
        {items.map((item, i) => (
          <Button
            data-testid="switch-bank-modal-close"
            on_click={() => {
              navigate(`/${item.url}`);
              onModalClose();
            }}
            tabIndex={0}
            aria-label="switch-bank-modal-close"
            key={i}
          >
            <div className={styles.button_content}>
              <span className={styles.name_avatar}>
                <Avatar size="medium">{getFirstLetter(item.name)}</Avatar>
              </span>
              <div className={styles.button_text_wrapper}>
                {item.name && <span>{item.name}</span>}
                {item.id && (
                  <span className={styles.account_id}>{item.id}</span>
                )}
                {item.role && <span>{item.role}</span>}
              </div>
              <IconPrimary
                className={styles.button_icon}
                icon="chevron_right"
                size="auto"
                left="xx-small"
              />
            </div>
          </Button>
        ))}
      </div>
    ) : null;
  };

  const accountList = onlineBankList?.[menuLocale] || [];
  const pmUsers = accountList.filter((a) => a.isPrivate);
  const cbAccounts = accountList.filter((a) => !a.isPrivate);
  const [cbFinalList, setCbList] = useState(cbAccounts);

  const pmUsersList = getOnlineBankList(pmUsers);
  const cbAccountsList = getOnlineBankList(cbFinalList);

  return (
    <Modal
      focus_selector=".loginInput"
      title={<FormattedMessage id={translationKeys.dnb_header_switch_bank} />}
      close_title={menuLocale === 'no' ? 'Lukk' : 'Close'}
      trigger_hidden
      open_state="opened"
      on_close={() => {
        if (onModalClose) {
          onModalClose();
        }
      }}
      on_open={onOpen}
      class={styles.modal_container}
      style={{ ...(!isDesktop && { padding: '0 16px' }) }}
      fullscreen={!isDesktop}
      max_width={!isDesktop ? undefined : '43rem'}
    >
      {pmUsersList}
      <div className={styles.company_list}>
        <div className={styles.company_search_box}>
          <span className={styles.search_title}>
            <FormattedMessage
              id={translationKeys.dnb_header_switch_search_text}
            />
          </span>
          <Input
            clear={true}
            stretch={true}
            icon="loupe"
            className={styles.search_input}
            placeholder={searchPlaceholder}
            top
            on_change={({ value }) => {
              const filteredCbList = cbAccounts.filter((company) =>
                company.name.toLowerCase().includes(value)
              );
              setCbList(filteredCbList);
            }}
          />
        </div>
        {cbAccountsList}
      </div>
    </Modal>
  );
};

export default SwitchBankModal;
