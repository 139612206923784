import React from 'react';
import {
  createSkeletonClass,
  skeletonDOMAttributes,
  SkeletonContextProps,
} from '@dnb/eufemia/components/skeleton/SkeletonHelper';
import { createSpacingClasses } from '@dnb/eufemia/components/space/SpacingHelper';
import Context, {ContextProps} from '@dnb/eufemia/shared/Context';
import {
  validateDOMAttributes,
  extendPropsWithContext,
} from '@dnb/eufemia/shared/component-helper';
import { translatePath } from '@dnb/path-translation';
import { Location } from '@reach/router';
import classnames from 'classnames';
// eslint-disable-next-line no-restricted-imports
import { Link as Anchor } from 'gatsby';

export { Location };

export const renderCallbacks = [];
export function transformPropsBeforeRender(callback) {
  if (!renderCallbacks.includes(callback)) {
    renderCallbacks.push(callback);
  }
}

type InnerRefType = React.Ref<HTMLAnchorElement>;
export interface LinkPropTypes {
  to: string;
  skeleton?: boolean;
  style?: React.CSSProperties;
  className?: string;
  innerRef?: InnerRefType;
  children?: React.ReactChild;
  activeClassName?: string;
  partiallyActive?: boolean;
  activeStyle?: string | { textDecoration: string };
  state?: Record<string, unknown>;
  top?: string | number | boolean;
  right?: string | number | boolean;
  bottom?: string | number | boolean;
  left?: string | number | boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
interface ContextTypes extends SkeletonContextProps {
  skeleton?: boolean;
}
const LinkWithRef = React.forwardRef(
  (props: LinkPropTypes, ref: InnerRefType) => {
    return <Link innerRef={ref} {...props} />;
  }
);
LinkWithRef.displayName = 'Link';

export default LinkWithRef;

export function Link({
  innerRef,
  skeleton,
  className,
  ...rest
}: LinkPropTypes) {
  const context: ContextProps = React.useContext(Context);
  const props =
    skeleton !== false && typeof context?.skeleton !== 'undefined'
      ? extendPropsWithContext(rest, null, {
          skeleton: context?.skeleton,
        })
      : rest;

  props.to = translatePath(props.to);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  props.className = classnames(
    'dnb-anchor',
    className,
    createSpacingClasses(props, 'a'),
    createSkeletonClass('font', skeleton, context)
  );

  validateDOMAttributes(null, props);
  skeletonDOMAttributes(props, skeleton, context);

  if (renderCallbacks.length > 0) {
    renderCallbacks.forEach((callback) => {
      if (typeof callback === 'function') {
        Object.assign(props, callback(props));
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Anchor ref={innerRef} {...props} tabIndex={0} aria-label="navigation-item"/>;
}
