import create from 'zustand';
import { getAuthenticationState } from '@dnb/authentication';

type DNBMenuStoreTypes = {
  isMenuOpen: boolean;
  setMenuOpen: (isMenuOpen: boolean) => void;
  toggleMenuOpen: () => void;
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  toggleIsLoggedIn: () => void;
  activeMenuTab: string;
  setActiveMenuTab: (activeMenuTab: string) => void;
  menuLocale: locales;
  setMenuLocale: (menuLocale: locales) => void;
  lastLogin: Date;
  setLastLogin: (lastLogin: Date) => void;
};

export type locales = 'no' | 'en';

const { isLoggedIn } = getAuthenticationState();
const lastLoginDate = new Date(2022, 5, 28, 13, 39, 0)

export const menuStore = create<DNBMenuStoreTypes>((set, get) => ({
  isMenuOpen: false,
  setMenuOpen: (isMenuOpen: boolean) => {
    set({ isMenuOpen });
  },
  toggleMenuOpen: () => {
    const isMenuOpen = !get().isMenuOpen;
    set({ isMenuOpen });
  },
  isLoggedIn: isLoggedIn,
  setIsLoggedIn: (isLoggedIn: boolean) => {
    set({ isLoggedIn });
  },
  toggleIsLoggedIn: () => {
    set({ isLoggedIn: !get().isLoggedIn });
  },
  activeMenuTab: '',
  setActiveMenuTab: (activeMenuTab: string) => {
    set({ activeMenuTab });
  },
  menuLocale: 'no',
  setMenuLocale: (menuLocale: locales) => {
    set({ menuLocale });
  },
  lastLogin: lastLoginDate,
  setLastLogin: (lastLogin: Date) => {
    set({ lastLogin });
  },
}));

/**
 * This this React hook inside your React components
 */
const useMenuStore = menuStore;

export { useMenuStore };
