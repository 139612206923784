import React from 'react';
import { getAuthenticationState } from '@dnb/authentication';
import { Provider as EufemiaProvider } from '@dnb/eufemia/shared';
import { useLanguage } from '@dnb/locale';
import classNames from 'classnames';
import styles from './HeaderControlsWrapper.module.scss';
import HeaderControlsNoWrapper from './components/HeaderControls/HeaderControls';
import { createHeadroomHook } from './hooks/UseHeadroom/useHeadroom';

const useHeadroom = createHeadroomHook();

export default function HeaderControlsWrapper() {
  const { locale } = useLanguage();
  const { isLoggedIn } = getAuthenticationState();
  const { headroomRef, shouldOpenChildren } = useHeadroom({});

  return (
    <EufemiaProvider locale={locale}>
      <div
        ref={headroomRef}
        className={classNames(
          styles.headerControlsWrapper,
          'headerControlsWrapper'
        )}
      >
        <HeaderControlsNoWrapper
          isLoggedIn={isLoggedIn}
          shouldOpen={shouldOpenChildren}
        />
      </div>
    </EufemiaProvider>
  );
}

HeaderControlsWrapper.displayName = 'HeaderControls';
