import axios from 'axios';
import { getCookieValue, Language } from './locale';

export const DNBPlatformCookie = 'portal_scriptable';
export const DNBPlatformName = 'DNB_NO';
export const DNBPlatformLanguageUrl = '/appo/portal/setLanguage';

/**
 * Get the e-platform locale cookie.
 *
 * @param defaultLocale (optional) The default locale to return if no e-platform cookie is found. Defaults to null.
 * @returns The e-platform locale cookie
 */
export const getPlatformLocale = (
  defaultLocale: string | null = null
): string => {
  const portalScriptableValue = getCookieValue(DNBPlatformCookie);

  if (!portalScriptableValue) {
    return defaultLocale;
  } else {
    try {
      // "portal_scriptable" cookie is on the format { "portalsegmentation":"Roles=CHROME_100,","ir":".d1hc0u0.ru1.rc3","portallanguage":"DNB_NO=NO" }
      // and for english: { ..., "portallanguage":"DNB_NO=EN" }
      // and for other pages {..., ,"portallanguage":"DNB_SE=SV" }
      const portalLocale = JSON.parse(
        portalScriptableValue
      )?.portallanguage?.split('=')[1];

      return portalLocale;
    } catch (e) {
      return defaultLocale;
    }
  }
};

/**
 * For development purposes, this function overwrites the cookie from e-platform and sets cookie directly.
 * @param toLocale The locale to set the cookie to, on the format 'EN' or 'NO'.
 */
export const setDevelopmentLangCookie = (toLocale: Language) => {
  const shortLang = getShortLang(toLocale);
  const prevCookie = getCookieValue(DNBPlatformCookie);

  let portalScriptable;
  if (prevCookie) {
    portalScriptable = {
      ...JSON.parse(prevCookie),
      portallanguage: `${DNBPlatformName}=${shortLang}`,
    };
  } else {
    portalScriptable = {
      portallanguage: `${DNBPlatformName}=${shortLang}`,
    };
  }

  document.cookie = `${DNBPlatformCookie}=${JSON.stringify(
    portalScriptable
  )}; path=/`;
};

const getShortLang = (locale: Language) => {
  switch (locale) {
    case Language.GB:
      return 'EN';
    case Language.NO:
      return 'NO';
    case Language.TEST:
      return 'TEST';
    default:
      return 'EN';
  }
};

export const isPlatformLocaleChangeNeeded = (
  expectedLocale: Language
): boolean => {
  return (
    getPlatformLocale()?.toLowerCase() !==
    getShortLang(expectedLocale).toLocaleLowerCase()
  );
};

/**
 * Set the e-platform locale cookie using a axios fetch call.
 *
 * @param toLocale The locale to set e-platform cookie to. Use 'NO' or 'EN'.
 * @param site Overwrite the site sent with the axios fetch call. Defaults to 'DNB_NO'.
 * @returns The axios get promise. Use to handle request responses.
 */
export const setPlatformLocale = async (
  toLocale: string,
  site = DNBPlatformName
) => {
  const saveLanguageUrl = `${DNBPlatformLanguageUrl}?la=${toLocale.toUpperCase()}&site=${site}`;

  return await axios.get(saveLanguageUrl);
};
