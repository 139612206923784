import React, { SyntheticEvent, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { deleteAuthCookie } from '@dnb/authentication';
import { Button, Icon, IconPrimary, P } from '@dnb/eufemia';
import { chevron_down, profile_medium } from '@dnb/eufemia/icons';
import * as icons from '@dnb/eufemia/icons/secondary_icons_medium';
import { useHeaderStore } from '@dnb/header';
import { MenuButton } from '@dnb/header';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import translationKeys from '../../lib/translationKeys';
import About from './About/About';
import LoginHistory from './LoginHistory/LoginHistory';
import SwitchBankModal from './Modal/SwitchBankModal';
import styles from './ProfileDropdown.module.scss';
import { useOpenDropdown } from './UseOpenDropdown/useOpenDropdown';

type ProfileDropdownProps = { shouldOpen?: boolean };

const ProfileDropdown = ({ shouldOpen }: ProfileDropdownProps) => {
  const openButtonId = 'dnb_header_me_button';
  const [showSwitchBank, setShowSwitchBank] = useState(false);
  const { isDropdownOpen, refToPreventClose, toggleDropdown, closeDropdown } =
    useOpenDropdown(openButtonId, shouldOpen);
  const { formatMessage } = useIntl();
  const profile = useHeaderStore((state) => state.profile);

  const closeSwitchBank = () => {
    setShowSwitchBank(false);
  };

  const getIcon = (icon: string) => {
    if (!icon || !icons || !icons[icon]) {
      return null;
    }
    return (
      <Icon
        icon={icons[icon]}
        size="large"
        className={`${styles.menu_card_icon} position-start`}
        data-testid={`dnb-menu-link-icon-${icon}`}
      />
    );
  };

  const getItemContent = (icon: string, title: string, text: string) => {
    if (!icon || !icons || !icons[icon]) {
      return null;
    }
    return (
      <div className={styles.button_content}>
        {getIcon(icon)}
        <div className={styles.button_text_wrapper}>
          <FormattedMessage id={`${title}`} />
          {text && (
            <P className={styles.button_content_description}>
              <FormattedMessage id={`${text}`} />
            </P>
          )}
        </div>
        <IconPrimary icon="chevron_right" size="auto" left="xx-small" />
      </div>
    );
  };

  return (
    <>
      <MenuButton
        id={styles.dnb_header_me_button}
        arrowIcon={chevron_down}
        text={<FormattedMessage id={translationKeys.dnb_header_me_button} />}
        icon={profile_medium}
        aria-haspopup="true"
        aria-expanded={isDropdownOpen}
        on_click={(event: SyntheticEvent) => {
          // We stop the event from bubbling up the dom so it is not caught by the handler closing the dropdow
          event.nativeEvent.stopPropagation();
          toggleDropdown();
        }}
      />
      <div
        ref={refToPreventClose}
        className={classNames(styles.dropdown, 'dnb-no-focus', {
          [styles.dropdownOpen]: isDropdownOpen,
        })}
        aria-label={formatMessage({
          id: translationKeys.dnb_aria_header_me_dropdown,
        })}
      >
        <section>
          <DropdownItem className={styles.about}>
            <About
              name={profile?.name}
              avatarSrc={profile?.avatarSrc}
              programType={profile?.program}
              extraInfo={profile?.extraInfo}
            />
          </DropdownItem>
          <DropdownItem className={styles.headerCotrolsDropdown}>
            <Button
              on_click={() => {
                navigate('/profile');
                closeDropdown();
              }}
              tabIndex={0}
            >
              {getItemContent(
                'profile_medium',
                translationKeys.dnb_header_my_profile,
                translationKeys.dnb_header_profile_details
              )}
            </Button>
          </DropdownItem>
          <DropdownItem className={styles.headerCotrolsDropdown}>
            <Button
              on_click={() => {
                navigate('/dele');
                closeDropdown();
              }}
            >
              {getItemContent(
                'people_2_medium',
                translationKeys.dnb_header_profile_address,
                translationKeys.dnb_header_share_profile
              )}
            </Button>
          </DropdownItem>
          <DropdownItem className={styles.headerCotrolsDropdown}>
            <Button
              on_click={() => {
                setShowSwitchBank(true);
                closeDropdown();
              }}
            >
              {getItemContent(
                'office_buildings_medium',
                translationKeys.dnb_header_switch_bank,
                null
              )}
            </Button>
            {showSwitchBank && (
              <SwitchBankModal onModalClose={() => closeSwitchBank()} />
            )}
          </DropdownItem>
          <DropdownItem className={styles.logOutButtonContainer}>
            <div className={styles.dropdownLogOut}>
              <Button
                on_click={() => {
                  deleteAuthCookie();
                  navigate('/');
                  closeDropdown();
                }}
              >
                <FormattedMessage id={translationKeys.dnb_header_me_log_out} />
              </Button>
              <LoginHistory></LoginHistory>
            </div>
          </DropdownItem>
        </section>
      </div>
    </>
  );
};

const DropdownItem: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div className={classNames(styles.dropdownItem, className)}>{children}</div>
  );
};

export default ProfileDropdown;
