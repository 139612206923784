import React, {MutableRefObject, RefObject, useRef} from 'react';
import MenuItemContainer from '../MenuItemContainer/MenuItemContainer';
import styles from './MenuTab.module.scss';
import {useMenuStore} from "../../store/MenuStore";
import classNames from "classnames";
import { FormattedMessage } from 'react-intl';
import {useMediaQuery} from 'react-responsive';
import { FormStatus } from '@dnb/eufemia';
import translationKeys from '../../lib/translationKeys';

function MenuTab({content}) {
  const {
    isMobileMenuOpen,
    isMenuOpen,
    setMenuOpen,
    toggleMenuOpen,
    setMobileMenuOpen,
    returnToSmallView,
    prevActiveMenuItemRef,
    setPrevActiveMenuItemRef,
    prevMenuActive,
    setPrevMenuActive,
    responseError,
  } = useMenuStore();

  const setMenuActive = (menuActive: MutableRefObject<boolean>, value: boolean) => {
    menuActive.current = value;
  };
  const isDesktop = useMediaQuery({minWidth: '50em'});
  const menuRefTimer = useRef(null);
  const visibilityTimer = useRef(null);

  const collapseMenuItem = (paramRef: RefObject<HTMLDivElement>) => {
    if(paramRef == null || paramRef.current == null) return;
    const elem = paramRef.current.getElementsByClassName("submenuContainer").item(0) as HTMLElement;
    if(elem == null) return;
    //to collapse submenu (css animation reacts to the height change)
    elem.style.height = `0px`;
    //during animation not to see hidden submenu items
    elem.style.overflow = 'hidden';
    paramRef.current.classList.remove('active');
    visibilityTimer.current = setTimeout(function () {
      /*vertical animation - visibility hidden is used instead of overflow hidden to be able
   to se borders navigating with keyboard to control if items can be focused or not*/
      //to make focus to not work on hidden elements
      this.style.visibility = 'hidden';
      //to make border visible on focus (keyboard)
      this.style.overflow = 'visible';
    }.bind(elem), 400);

  };

  const needToAnimate = (activeMenuItemRef: RefObject<HTMLDivElement>) => {
     if(activeMenuItemRef != null && activeMenuItemRef.current != null) activeMenuItemRef.current.classList.remove('noAnimation');
  }

  const onClick = (activeMenuItemRef: RefObject<HTMLDivElement>, menuActive: MutableRefObject<boolean>) => {
    const t = !menuActive.current;
    if(prevActiveMenuItemRef!=null && prevActiveMenuItemRef.current !== null && prevActiveMenuItemRef.current !== activeMenuItemRef.current){
      needToAnimate(prevActiveMenuItemRef);
      collapseMenuItem(prevActiveMenuItemRef);
      prevMenuActive.current=false;
      setPrevMenuActive(prevMenuActive);
    }

    needToAnimate(activeMenuItemRef);
    if (t) {
      if (visibilityTimer.current !== null) {
        clearTimeout(visibilityTimer.current);
      }
      activeMenuItemRef.current.classList.add('active');
      const elem = activeMenuItemRef.current.getElementsByClassName("submenuContainer").item(0) as HTMLElement;
      //before animation to make submenu visible
      elem.style.visibility = 'visible';
      //to expand submenu (css animation reacts to the height change)
      const itemHeight = (elem.children.item(0).clientHeight !== 0 )? elem.children.item(0).clientHeight : 56;
      //height of the submenu
      elem.style.height = `${(elem.children.length) * itemHeight}px`;
    } else {
      collapseMenuItem(activeMenuItemRef);
    }
    if(activeMenuItemRef !== null && prevActiveMenuItemRef!=null && prevActiveMenuItemRef.current === activeMenuItemRef.current){
      //clicked on the same item again
      setPrevActiveMenuItemRef(null);
    } else {
      setPrevActiveMenuItemRef(activeMenuItemRef);
    }
    setMenuActive(menuActive, t);
    setPrevMenuActive(menuActive);
  };

  const closeMenu = (activeMenuItemRef: RefObject<HTMLDivElement>, menuActive: MutableRefObject<boolean>) => {
    if (menuRefTimer.current !== null) {
      clearTimeout(menuRefTimer.current);
    }
    setPrevActiveMenuItemRef(null);
    if(prevMenuActive != null){
      prevMenuActive.current=false;
      setPrevMenuActive(prevMenuActive);
    }
    setMenuActive(menuActive, false);
    collapseMenuItem(prevActiveMenuItemRef);
    menuRefTimer.current = setTimeout(() => {
      window.scrollTo(0, 0);
      if(!isDesktop) {
        //pressing on menu items close mobile menu
        setMobileMenuOpen(false);
      }
      //check if need to return to small view
      if (returnToSmallView) {
         setMenuOpen(false);
      }
    }, 10);
  }

  const onClickHandler = (activeMenuItemRef: RefObject<HTMLDivElement>, url: string, menuActive: MutableRefObject<boolean>) => {
    //if menu is small
    if (!isMenuOpen) {
      //expand/open menu to the right and close active menu
      toggleMenuOpen();
      //if clicked on the same menu item (it was already selected/green because) so need just expand to the right with no more actions
      if(menuActive != null && menuActive.current) return;
    }

    //open submenu
    onClick(activeMenuItemRef, menuActive);
  }

  const showMenuItems = () => {
    return content &&
      content.map((item, i) => (
        <MenuItemContainer key={i}
                           index={i}
                           item={item}
                           closeMenu={closeMenu}
                           onClickHandler={onClickHandler}
        ></MenuItemContainer>
      ))
  }

  return (
    <div
         className={isMobileMenuOpen && !isDesktop ? classNames(styles.dnb_sidebarMenuContentWrapper, styles.mobileMenuView) : styles.dnb_sidebarMenuContentWrapper}>
      {responseError ? (
        <div className={styles.formErrorWrapper}>
          {(<FormStatus
            text={<FormattedMessage id={translationKeys.dnb_menu_failing_fetch} />}
            className={styles.formErrorStatus}
          />)}
        </div>
      ) :
        (showMenuItems())
      }
    </div>
  );
}

export default MenuTab;
