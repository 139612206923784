interface AuthSettings {
  isAuthFlowEnabled: boolean;
  authRedirectUrl: string;
}

const settings: AuthSettings = {
  isAuthFlowEnabled: false,
  authRedirectUrl: '',
};

export const setAuthSettings = (
  isAuthFlowEnabled: boolean,
  authRedirectUrl: string
) => {
  settings.isAuthFlowEnabled = isAuthFlowEnabled;
  settings.authRedirectUrl = isAuthFlowEnabled ? authRedirectUrl : '';
};

export const isLocalAuthDevelopment = () => settings.isAuthFlowEnabled;

export const getLocalAuthRedirectURL = (fallbackURL: string) =>
  isLocalAuthDevelopment() ? settings.authRedirectUrl ?? '/' : fallbackURL;
