export const bottomBarContents = {
  no: [
    {
      title: 'Private Banking',
      url: '/pb',
    },
    {
      title: 'Markets',
      url: '/markets',
    },
    {
      title: 'DNB Nyheter',
      url: '/nyheter',
    },
    {
      title: 'Hjelp og kundeservice',
      url: '/HJELP',
    },
    {
      title: 'Om oss',
      url: '/omoss',
    },
  ],
  en: [
    {
      title: 'Private Banking',
      url: '/pb',
    },
    {
      title: 'Markets',
      url: '/markets',
    },
    {
      title: 'DNB News',
      url: '/nyheter',
    },
    {
      title: 'Help',
      url: '/HJELP',
    },
    {
      title: 'About us',
      url: '/about',
    },
  ],
};
