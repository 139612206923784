import React from 'react';
import { Tabs } from '@dnb/eufemia';
import {
  getMenuContent,
  MenuTabsContent,
  getBottomBarContent,
  MenuBottomBarContent,
} from '../../MenuMaker';
import { useMenuStore } from '../../store/MenuStore';
import MenuBottomBar from '../MenuBottomBar/MenuBottomBar';
import MenuTab from '../MenuTab/MenuTab';

export interface MenuContentProps {
  menuTabsContent?: MenuTabsContent[];
  menuBottomBarContent?: MenuBottomBarContent[];
  isLoggedIn?: boolean;
}

export default function MenuContent({
  menuTabsContent,
  menuBottomBarContent,
  isLoggedIn,
}: MenuContentProps) {
  const { menuLocale } = useMenuStore();
  const menuTabsContentConst = menuTabsContent || getMenuContent(menuLocale);
  const menuBottomBarContentConst =
    menuBottomBarContent || getBottomBarContent(menuLocale);

  const tabsData = menuTabsContentConst.map(({ name, tabKey }) => {
    return { title: name, key: tabKey };
  });

  const tabsContent =
    Object.assign(
      {},
      ...menuTabsContentConst.map(({ tabKey, menuItems }) => {
        return {
          [tabKey]: () => <MenuTab content={menuItems} />,
        };
      })
    ) || {};

  const activeMenuTab = useMenuStore((state) => state.activeMenuTab);
  const setActiveMenuTab = useMenuStore((state) => state.setActiveMenuTab);

  return (
    <nav>
      <Tabs
        data={tabsData}
        selected_key={activeMenuTab}
        tabs_style="lavender"
        on_change={({ selected_key }) => setActiveMenuTab(selected_key)}
      >
        {tabsContent}
      </Tabs>
      {!isLoggedIn && <MenuBottomBar links={menuBottomBarContentConst} />}
    </nav>
  );
}
