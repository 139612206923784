import React from 'react';
import {Breadcrumb} from '@dnb/eufemia';
import {SidebarMenuButtonWrapper} from '@dnb/sidebarmenu';
import {useMenuStore} from '@dnb/sidebarmenu';
import HeaderControls from '@dnb/header-controls';
import classNames from "classnames";
import styles from './Header.module.scss';
import {useMediaQuery} from 'react-responsive';
import {LogoButton} from "../LogoButton/LogoButton";

export function Header({breadcrumbPath}) {
  const {isMobileMenuOpen} = useMenuStore();
  const isDesktop = useMediaQuery({minWidth: '50em'});
  return (
      <div className={(isDesktop) ? styles.sidebarLogo : classNames(styles.sidebarLogo, styles.mobileMenuView)}>
        <LogoButton viewport={"other"}></LogoButton>
        <div className={styles.breadcrumbheaderwrapper}>
          <SidebarMenuButtonWrapper/>
          <LogoButton viewport={"tablet"}></LogoButton>
          <div
            className={classNames(styles.pushHeader, (!isDesktop && isMobileMenuOpen) ? styles.pushHeaderActive : "")}></div>
            <Breadcrumb data={breadcrumbPath} className={(isDesktop) ? styles.breadcrumb : styles.hidden} spacing/>
          <div
            className={classNames(styles.headerrightwrapper, (!isDesktop && isMobileMenuOpen) ? styles.pushHeaderActive : "")}>
            <HeaderControls></HeaderControls></div>
        </div>
      </div>
  );
}
