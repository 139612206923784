import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';
import { IntlProvider } from 'react-intl';
import Button from '@dnb/eufemia/components/Button';
import GlobalError from '@dnb/eufemia/components/GlobalError';
import { Language, useLanguage } from '@dnb/locale';
import translationKeys from '../../lib/translationKeys';
import translations from '../../lib/translations';

interface CustomFallbackProps extends FallbackProps {
  showRetryButton?: boolean;
  locale?: Language;
}

export default function GlobalErrorFallback({
  error,
  resetErrorBoundary,
  showRetryButton,
  locale: localeFromProps,
}: CustomFallbackProps): JSX.Element {
  const { locale: DNBLocale } = useLanguage();
  const locale = localeFromProps || DNBLocale;

  return (
    <IntlProvider locale={locale} key={locale} messages={translations[locale]}>
      <GlobalError
        status="500"
        title={
          <FormattedMessage id={translationKeys.dnb_error_boundary_title} />
        }
        text={
          error?.message || (
            <FormattedMessage
              id={translationKeys.dnb_error_boundary_subtitle}
            />
          )
        }
        back={
          showRetryButton &&
          resetErrorBoundary && (
            <Button
              data-testid="dnb-error-boundary-tryagain"
              on_click={resetErrorBoundary}
            >
              <FormattedMessage
                id={translationKeys.dnb_error_boundary_tryagain}
              />
            </Button>
          )
        }
        data-testid="dnb-global-error-boundary"
      />
    </IntlProvider>
  );
}
