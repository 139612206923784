import React, {MutableRefObject, RefObject, useRef} from 'react';
import styles from './MenuItemContainer.module.scss';
import MenuItem from "../MenuItem/MenuItem";
import {MenuItemData} from "../../MenuServiceDataType";
import classNames from "classnames";

interface SubMenuItemProps {
  submenuItems: Array<MenuItemData>,
  targetRef?: RefObject<HTMLDivElement>;
  menuActive: MutableRefObject<boolean>;
  closeMenu: (activeMenuItemRef: RefObject<HTMLDivElement>, menuActive: MutableRefObject<boolean>) => void;
  onClickHandler: (activeMenuItemRef: RefObject<HTMLDivElement>, url: string, menuActive: MutableRefObject<boolean>) => void;
}

const Itemsubmenu: React.FunctionComponent<SubMenuItemProps> = ({submenuItems, targetRef, menuActive, closeMenu, onClickHandler}) => {
  return (
    <div className={classNames(styles.submenuContainer, 'submenuContainer')}>
      {submenuItems &&
        submenuItems.map(({id, title, icon, description, url}, index) => {
          return (
            <div key={title + index + "submenu"}>
              <MenuItem
                key={title + index}
                id={id}
                title={title}
                icon={icon}
                text={description}
                url={url}
                targetRef={targetRef}
                menuActive={menuActive}
                closeMenu={closeMenu}
                onClickHandler={onClickHandler}
              />
            </div>
          )
        })}
    </div>
  );
}

export default function MenuItemContainer({index, item, closeMenu, onClickHandler}) {
  let activeLink = false;
  const {id, title, url, icon, description, submenuItems} = item;
  const menuItemContainerRef = useRef<HTMLDivElement>(null);
  const menuActive = useRef<boolean | false>(false);

  const keepSecondarymenuOpen = () => {
    if (submenuItems) {
      submenuItems.forEach((submenuItem) => {
        if (typeof window !== "undefined" && (window.location.pathname.endsWith(submenuItem['url']) || window.location.pathname.indexOf(submenuItem['url']+"/") != -1)) {
          activeLink = true;
        }
      });
    }
  };

  const menuitemcontainercss = () => {
    if (activeLink)
      return classNames("menuItemContainer", "activeLink", "noAnimation") + "";
    else
      return "menuItemContainer"
  };

  keepSecondarymenuOpen();

  return (
    <div className={menuitemcontainercss()} key={title + index + "item"} ref={menuItemContainerRef}>
      <MenuItem
        key={title + index}
        id={id}
        title={title}
        icon={icon}
        text={description}
        url={url}
        hassubmenu={submenuItems ? true : false}
        targetRef={menuItemContainerRef}
        menuActive={menuActive}
        closeMenu={closeMenu}
        onClickHandler={onClickHandler}
      />
      {submenuItems && <Itemsubmenu submenuItems={submenuItems}
                                    targetRef={menuItemContainerRef}
                                    menuActive={menuActive}
                                    closeMenu={closeMenu}
                                    onClickHandler={onClickHandler}/>}
    </div>
  );
}
