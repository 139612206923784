import React from 'react';
import styles from './AppsWrapper.module.scss';

export default function AppsWrapper({ children }) {
  // Use role to support legacy browsers
  // The ID dnb-app-content is used by the "dnb-skip-link"

  return (
    <main
      role="main"
      id="dnb-app-content"
      key="main-content"
      className={styles.appsWrapper}
    >
      {children}
    </main>
  );
}
