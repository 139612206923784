import React from 'react';
import { IconPrimary, P, Icon } from '@dnb/eufemia';
import * as icons from '@dnb/eufemia/icons/secondary_icons_medium.js';
import Link from '@dnb/link';
import classNames from 'classnames';
import styles from './MenuItem.module.scss';

interface MenuItemProps {
  id?: string | number;
  title: string;
  icon?: string;
  text?: string;
  children?: React.ReactElement;
  url?: string;
  linkClassName?: string;
}

const getIcon = (icon: string) => {
  if (!icon || !icons || !icons[icon]) {
    return null;
  }
  return (
    <Icon
      icon={icons[icon]}
      size="large"
      className={`${styles.menuCardIcon} position-start`}
      data-testid={`dnb-menu-link-icon-${icon}`}
    />
  );
};

export default function MenuItem({
  icon,
  title,
  text,
  children,
  url,
  linkClassName,
}: MenuItemProps) {
  return (
    <div className={styles.dnb_menuCard}>
      <Link
        to={url}
        className={classNames(styles.dnb_menuLink, linkClassName)}
        data-testid={`dnb-menu-link-${url}`}
      >
        <div className={styles.dnb_menuCardContent}>
          {getIcon(icon)}
          <div className={styles.dnb_menuCardTextWrapper}>
            {title}
            <IconPrimary icon="chevron_right" size="auto" left="xx-small" />
            {text && <P className={styles.dnb_menuCardDescription}>{text}</P>}
          </div>
        </div>
      </Link>

      {children}
    </div>
  );
}
