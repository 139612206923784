// Autogenerated file
const translationKeys = {
  dnb_error_boundary_tryagain: 'dnb_error_boundary_tryagain',
  dnb_error_boundary_title: 'dnb_error_boundary_title',
  dnb_error_boundary_subtitle: 'dnb_error_boundary_subtitle',
};

Object.freeze(translationKeys);

export default translationKeys;
