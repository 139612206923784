export const onlineBankList = {
  "no":[
    {
      "name":"Petter Nordmann",
      "url":"nettbanka",
      "isPrivate": true
    },
    {
      "name":"Adresseavisa",
      "url":"nettbankb",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Vanlig bruker"
    },
    {
      "name":"Aftenposten",
      "url":"nettbankc",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Administrator"
    },
    {
      "name":"Bergens Tidende",
      "url":"nettbankd",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Vanlig bruker"
    },
    {
      "name":"Bistro AS",
      "url":"nettbankb",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Bestiller"
    },
    {
      "name":"Bompalopalipi ASA",
      "url":"nettbankc",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Bestiller"
    },
    {
      "name":"Bussene",
      "url":"nettbankd",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Bestiller"
    },
    {
      "name":"Busselskapet",
      "url":"nettbankb",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Bestiller"
    },
    {
      "name":"Da Vida la Diva",
      "url":"nettbankc",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Bestiller"
    },
    {
      "name":"De store guttene AS",
      "url":"nettbankd",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Bestiller"
    },
    {
      "name":"Deres Egne Hjelpere AS",
      "url":"nettbankd",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Bestiller"
    },
    {
      "name":"Frankensteins Bruder AS",
      "url":"nettbankb",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Bestiller"
    },
    {
      "name":"Gloser og Verb AS",
      "url":"nettbankc",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Bestiller"
    },
    {
      "name":"Gompene",
      "url":"nettbankd",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Bestiller"
    },
    {
      "name":"Gomper & Sønn",
      "url":"nettbankc",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Bestiller"
    },
    {
      "name":"Himalaya Expressbuss AS",
      "url":"nettbankd",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Bestiller"
    }
  ],
  "en":[
    {
      "name":"Petter Nordmann",
      "url":"onlinebanka",
      "isPrivate": true
    },
    {
      "name":"Adresseavisa",
      "url":"onlinebankb",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Regular User"
    },
    {
      "name":"Aftenposten",
      "url":"onlinebankc",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Administrator"
    },
    {
      "name":"Bergens Tidende",
      "url":"onlinebankd",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Regular User"
    },
    {
      "name":"Bistro AS",
      "url":"nettbankb",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Ordering"
    },
    {
      "name":"Bompalopalipi ASA",
      "url":"nettbankc",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Ordering"
    },
    {
      "name":"Bussene",
      "url":"nettbankd",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Ordering"
    },
    {
      "name":"Busselskapet",
      "url":"nettbankb",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Ordering"
    },
    {
      "name":"Da Vida la Diva",
      "url":"nettbankc",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Ordering"
    },
    {
      "name":"De store guttene AS",
      "url":"nettbankd",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Ordering"
    },
    {
      "name":"Deres Egne Hjelpere AS",
      "url":"nettbankd",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Ordering"
    },
    {
      "name":"Frankensteins Bruder AS",
      "url":"nettbankb",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Ordering"
    },
    {
      "name":"Gloser og Verb AS",
      "url":"nettbankc",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Ordering"
    },
    {
      "name":"Gompene",
      "url":"nettbankd",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Ordering"
    },
    {
      "name":"Gomper & Sønn",
      "url":"nettbankc",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Ordering"
    },
    {
      "name":"Himalaya Expressbuss AS",
      "url":"nettbankd",
      "isPrivate": false,
      "id": "TB XXXXX",
      "role": "Ordering"
    }
  ]
}
