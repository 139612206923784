import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getAuthenticationState } from '@dnb/authentication';
import { Logo } from '@dnb/eufemia/components';
import { hamburger_medium } from '@dnb/eufemia/icons';
import {
  HeaderControlsNoWrapper,
  createHeadroomHook,
} from '@dnb/header-controls';
import Menu, { useMenuStore } from '@dnb/menu';
// eslint-disable-next-line no-restricted-imports
import { Link } from 'gatsby';
import translationKeys from '../../lib/translationKeys';
import MenuButton from '../MenuButton/MenuButton';
import styles from './Header.module.scss';

const useHeadroom = createHeadroomHook();

const Header = () => {
  const { isLoggedIn } = getAuthenticationState();
  const { toggleMenuOpen, isMenuOpen } = useMenuStore();
  const { headroomRef, shouldOpenChildren } = useHeadroom({
    reset: isMenuOpen,
  });

  return (
    <>
      <header className={styles.dnbHeader} ref={headroomRef}>
        <MenuButton
          text={
            <FormattedMessage id={translationKeys.dnb_header_menu_button} />
          }
          icon={hamburger_medium}
          on_click={() => {
            toggleMenuOpen();
          }}
        />

        <Link to="/" className={styles.dnbHeaderLogo} aria-label="DNB logo">
          <Logo height={40} />
        </Link>

        <HeaderControlsNoWrapper
          isLoggedIn={isLoggedIn}
          shouldOpen={shouldOpenChildren}
        />
      </header>

      <Menu />
    </>
  );
};

export default Header;
