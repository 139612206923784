import { useRef, useEffect, useState } from 'react';

export type HeadroomProps = {
  reset?: boolean;
  transition?: string;

  /**
   * How long to wait, until the component interacts
   * Usefull for auto scrollTop set by code
   */
  timeToInteract?: number;
};
export type HeadroomHElement = HTMLElement & HTMLParagraphElement;
export type HeadroomHook = (props: HeadroomProps) => {
  headroomRef: React.RefObject<HeadroomHElement>;
  shouldOpenChildren: boolean;
};
export type HeadroomReturn = () => HeadroomHook;

const defaultHeadroomValues = {
  reset: false,
  transition: 'transform 600ms cubic-bezier(0.04, 0.65, 0.55, 0.95)',

  /**
   * / Defaults to same as the animation used when opening the menu.
   */
  timeToInteract: 3000,
};

export const createHeadroomHook: HeadroomReturn = () => {
  // The relative scroll position will change during different states
  let relativeScrollPosition = 0;
  let headerHeight = 0;
  let timeDiff = 0;
  let prevScrollPosition = 0;

  const useHeadroom = (props?: HeadroomProps) => {
    const { reset, transition, timeToInteract } = {
      ...defaultHeadroomValues,
      ...props,
    };
    const headroomRef = useRef<HeadroomHElement>(null);
    const [shouldOpenChildren, setShouldOpenChildren] = useState(true);

    useEffect(() => {
      const element = headroomRef.current;
      const setup = () => {
        if (!element) {
          return console.error('headroomRef not found.');
        }

        const showHeadroom = reset || headerHeight !== element.clientHeight;

        if (showHeadroom) {
          // reset the height to zero, so it gets set during next scroll interaction
          headerHeight = 0;
        }

        // Use requestAnimationFrame to be able to change the value right before painting
        // In other words, the reset does not work without requestAnimationFrame
        window.requestAnimationFrame(() => {
          if (element?.style) {
            element.style.setProperty(
              'transition',
              reset ? transition : 'none'
            );
            if (reset) {
              document.body.style.overflow = 'unset'; // Hack to fix modal issue with overflow hiding the header.
            }
          }
        });
      };

      setup();

      if (typeof ResizeObserver !== 'undefined' && element) {
        const resizeObserver = new ResizeObserver(() => {
          if (headerHeight > 0 && headerHeight !== element.clientHeight) {
            setup();
          }
        });

        resizeObserver.observe(element);

        return () => resizeObserver.unobserve(element);
      }
    }, [headroomRef, reset, transition]);

    useEffect(() => {
      const element = headroomRef.current;
      if (!element) {
        return; // stop here
      }
      // Setting up default styles for the object
      // but only during init

      const timestamp = Date.now();

      const handleScroll = () => {
        // Because the height during startup is wrong when i screen is in small size, we set the height first during scroll
        if (headerHeight === 0) {
          headerHeight = element.clientHeight;
          relativeScrollPosition = headerHeight;
        }
        const yPosition = headerHeight === 0 ? 0 : window.pageYOffset;

        timeDiff =
          timeDiff < timeToInteract ? Date.now() - timestamp : timeToInteract;

        // Let the page scroll to its initial position, before we change the headroom
        if (timeDiff < timeToInteract) {
          relativeScrollPosition = headerHeight;
        } else {
          // On scrolling down
          if (yPosition > prevScrollPosition && relativeScrollPosition > 0) {
            relativeScrollPosition += prevScrollPosition - yPosition;
          }

          // On scrolling up
          else if (
            yPosition < prevScrollPosition &&
            headerHeight > relativeScrollPosition
          ) {
            relativeScrollPosition -= yPosition - prevScrollPosition;
          }

          // Reset if value is out of range
          if (relativeScrollPosition < 0) {
            relativeScrollPosition = 0;
          } else if (relativeScrollPosition > headerHeight || yPosition <= 0) {
            /**
             * NB: With "yPosition <= 0" we take care of mobile negative scroll bouncing
             */

            relativeScrollPosition = headerHeight;
          }
        }

        setShouldOpenChildren(relativeScrollPosition !== 0);
        prevScrollPosition = yPosition;
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [headroomRef, timeToInteract]);

    return { headroomRef, shouldOpenChildren };
  };

  return useHeadroom;
};
