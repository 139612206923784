import React from 'react';
import { PageProps } from 'gatsby';

interface ParamsPropsTypes extends Partial<PageProps> {
  pageContext: { appName?: string };
}
export interface ParamsType {
  element: React.ReactNode;
  props: ParamsPropsTypes;
}
interface PluginOptionsType {
  appName?: string;
}

/** callback that gets wrapped if the current page is with the current app, given by the name inside the plugin options */
type wrapAppCallbackType = (
  params: ParamsType,
  pluginOptions?: PluginOptionsType
) => React.ReactNode;

/** callback that gets gendered every time, like wrapPageCallback would do */
type wrapPageCallbackType = (
  params: ParamsType,
  pluginOptions?: PluginOptionsType
) => React.ReactNode;

/**
 * HOC/HOF helper to wrap the Gatsby wrapPageElement only for the app used by the current page
 *
 * @returns element given by wrapPageElement
 */
export default function wrapAppElement(
  wrapAppCallback?: wrapAppCallbackType,
  wrapPageCallback?: wrapPageCallbackType
) {
  return (params: ParamsType, pluginOptions: PluginOptionsType) => {
    if (!params.props.pageContext) {
      throw new Error(
        'wrapAppElement should only get assigned to wrapPageElement!'
      );
    }

    if (
      typeof pluginOptions.appName === 'undefined' ||
      params.props.pageContext.appName === pluginOptions.appName
    ) {
      if (typeof wrapAppCallback === 'function') {
        params.element = wrapAppCallback(params, pluginOptions);
      }
    }

    if (typeof wrapPageCallback === 'function') {
      params.element = wrapPageCallback(params, pluginOptions);
    }

    return params.element;
  };
}
