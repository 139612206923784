import { getCookie } from '@dnb/authentication';
import {
  getPlatformLocale,
  setPlatformLocale,
  isPlatformLocaleChangeNeeded,
  setDevelopmentLangCookie,
} from './eplatformLocale';

export enum Language {
  NO = 'nb-NO',
  GB = 'en-GB',
  TEST = 'test-TEST',
}
export const DNBLanguageCookieName = 'dnbnorlanguage';
export const DNBDefaultLanguage = Language.GB;
const isBrowser =
  typeof window !== 'undefined' && typeof document !== 'undefined';

/**
 * Get a cookie's value from the a given name
 *
 * @param cookieName The name of the cookie to retrieve
 * @returns The value of the given cookie (the text after `=`)
 */
export const getCookieValue = (cookieName: string) => {
  if (isBrowser) {
    const cookie = getCookie(cookieName);
    return cookie?.split(/=(.*)/s)[1];
  }
  return null;
};

/**
 * Sets locale settings on e-platform or sets language query param in the URL.
 * @param toLocale The language to be set, on the format `nb-NO`.
 */
export const setLang = (toLocale: Language) => {
  if (
    process.env.NODE_ENV !== 'development' &&
    process.env.NODE_ENV !== 'test'
  ) {
    if (isPlatformLocaleChangeNeeded(toLocale)) {
      setPlatformLocale(toLocale).catch((apiError) => {
        if (apiError.response.status === 404) {
          setDocumentLang(toLocale);
        }
      });
    }
  } else {
    setDevelopmentLangCookie(toLocale);
  }
};

/**
 * Sets the language in the HTML document attribute `lang`.
 * @param toLocale The language to be set, on the format `nb-NO`.
 */
export const setDocumentLang = (toLocale: Language) => {
  document.documentElement.setAttribute('lang', toLocale);
};

/**
 * Get the language from the 'lang' attribute in the HTML document.
 * @returns The language attribute of the HTML
 */
const getDocumentLang = (): string => {
  if (isBrowser) {
    return document.documentElement?.lang;
  }
  return null;
};

/**
 * detectLanguage
 * Converts string language keyword to Language enum if there is match
 * @param lang converts string language keyword to Language enum
 * @returns language with Language enum format or null
 */
export const detectLanguage = (lang: string): Language | null => {
  lang = lang.toLowerCase();

  if (lang === 'tests') {
    return Language.TEST;
  }

  if (lang.includes('no') || lang.includes('nb') || lang.includes('nn')) {
    return Language.NO;
  }

  if (lang.includes('en-gb') || lang.includes('en-us') || lang === 'en') {
    return Language.GB;
  }

  return null;
};

/**
 * Gets locale settings from e-platform, the query param, the HTML, the dnbnorlanguage cookie or the given default locale.
 * Supports Norwegian and English GB.
 * Returns locale on format nb-NO, needed by react-intl.
 * Returns English (en-GB) for languages different from Norwegian.
 *
 * @param defaultLocale (optional) Set a different fallback locale - default set to 'nb-NO'
 */
export const getLang = (defaultLocale = DNBDefaultLanguage): Language => {
  const lang =
    getPlatformLocale() ||
    getDocumentLang() ||
    getCookieValue(DNBLanguageCookieName) ||
    defaultLocale;

  return detectLanguage(lang) || defaultLocale;
};
