// Autogenerated file
const translationKeys = {
  dnb_footer_go_to_language: 'dnb_footer_go_to_language',
  dnb_footer_go_to_language_aria_label: 'dnb_footer_go_to_language_aria_label',
  dnb_footer_nav_to_top: 'dnb_footer_nav_to_top',
  dnb_footer_nav_to_top_aria_label: 'dnb_footer_nav_to_top_aria_label',
  dnb_footer_default_home_page_url: 'dnb_footer_default_home_page_url',
  dnb_footer_default_home_page_url_aria_label:
    'dnb_footer_default_home_page_url_aria_label',
  dnb_footer_days_since_last_visit: 'dnb_footer_days_since_last_visit',
};

Object.freeze(translationKeys);

export default translationKeys;
