import React from 'react';
import {useMenuStore} from '../../store/MenuStore';
import MenuTab from '../MenuTab/MenuTab';
import styles from './MenuContent.module.scss';
import {arrow_left, arrow_right} from "@dnb/eufemia/icons";
import {Button} from '@dnb/eufemia';
import {MenuItemData} from "../../MenuServiceDataType";
import classNames from 'classnames';
import {useMediaQuery} from 'react-responsive';
import {FormattedMessage} from "react-intl";
import translationKeys from "../../lib/translationKeys";

export interface MenuContentProps {
  menuItemList?: MenuItemData[];
}

export default function MenuContent({
                                      menuItemList
                                    }: MenuContentProps) {
  const {
    isMenuOpen,
    toggleMenuOpen,
    isMobileMenuOpen,
    setReturnToSmallView,
    responseError
  } = useMenuStore();

  const isDesktop = useMediaQuery({minWidth: '50em'});

  const toggleReturnToSmallView = () => {
    if (isMenuOpen) {
      //remember to collapse to small view after navigating in the menu
      setReturnToSmallView(true);
    } else {
      //don't need to collapse to small view because user expanded menu view
      setReturnToSmallView(false);
    }
  };

  const changeSize = () => {
    toggleMenuOpen();
    toggleReturnToSmallView();
  };

  const menuClosedCss = () => {
    if (isDesktop) {
      return classNames(styles.sidebarNav, styles.small, "small");
    } else {
      return classNames(styles.sidebarNav);
    }
  }

  const mobileMenuClosedCss = () => {
    if (isMenuOpen) {
      return styles.sidebarNav;
    } else {
      return menuClosedCss();
    }
  }

  const menucontentcss = () => {
    if (isMobileMenuOpen && !isDesktop) {
      return classNames(styles.sidebarNav, styles.mobileMenuView);
    } else {
      return mobileMenuClosedCss();
    }
  };

  return (
    <nav className={classNames(menucontentcss(), "menuNavigation")} aria-label="sidebar-menu">
      <MenuTab content={menuItemList}/>
      {!responseError && menuItemList.length > 0 && (<Button
        id="idToggleWidth"
        className={styles.toggleWidth}
        icon={isMenuOpen ? arrow_left : arrow_right}
        icon_position={"top"}
        variant="tertiary"
        text={
          isMenuOpen ? (
            <FormattedMessage id={translationKeys.dnb_menu_smaller} />
          ) : (
            <FormattedMessage id={translationKeys.dnb_menu_bigger} />
          )
        }
        on_click={changeSize}
      />)}
    </nav>
  );
}

